import * as R from 'ramda';
import { handleActions } from 'redux-actions';

import {
  inviteUserFailure,
  inviteUserRequest,
  inviteUserSuccess,
  inviteUserFetched,
  acceptInvitationsRequest,
  acceptInvitationsEnd,
} from '../actions/invitations';

const defaultState = {
  isInvitingUser: false,
  isInviteUser: false,
  isAcceptInvitations: false,
  invitationsUser: {},
};

export const invitationsReducers = handleActions(
  {
    [inviteUserRequest]: R.mergeDeepLeft({ isInvitingUser: true }),
    [inviteUserFailure]: R.mergeDeepLeft({ isInvitingUser: false }),
    [inviteUserSuccess]: R.mergeDeepLeft({
      isInviteUser: true,
      isInvitingUser: false,
    }),
    [inviteUserFetched]: R.mergeDeepLeft({ isInviteUser: false }),
    [acceptInvitationsRequest]: R.mergeDeepLeft({ isAcceptInvitations: true }),
    [acceptInvitationsEnd]: R.mergeDeepLeft({ isAcceptInvitations: false }),
  },
  defaultState
);
