import * as R from 'ramda';
import { combineActions, handleActions } from 'redux-actions';

import {
  fetchLibraryDataRequest,
  fetchLibraryDataSuccess,
  fetchLibraryDataFailure,
  createLibrarySectionRequest,
  createLibrarySectionSuccess,
  createLibrarySectionFailure,
  updateLibrarySectionSuccess,
  updateLibrarySectionFailure,
  deleteLibrarySectionSuccess,
  deleteLibrarySectionFailure,
  createLibraryEstimationTaskRequest,
  createLibraryEstimationTaskSuccess,
  createLibraryEstimationTaskFailure,
  // updateLibraryEstimationTaskRequest,
  updateLibraryEstimationTaskSuccess,
  updateLibraryEstimationTaskFailure,
  // deleteLibraryEstimationTaskRequest,
  deleteLibraryEstimationTaskSuccess,
  deleteLibraryEstimationTaskFailure,
  deleteLibraryEstimationTasksBySection,
} from '../actions/library';
import { arrayToObject, getChildIdsByParentId } from '../../utils/general';

const defaultState = {
  error: null,
  isFetching: false,
  isSectionCreateRequest: false,
  isEstimationTaskCreateRequest: false,
  libraryId: '',
  sections: {},
  estimationTasks: {},
};

const failureReducer = (state, { payload }) => ({
  ...state,
  error: payload,
  isFetching: false,
  isSectionCreateRequest: false,
  isEstimationTaskCreateRequest: false,
});

const fetchLibrarySuccessReducer = (state, { payload }) => ({
  ...state,
  error: null,
  isFetching: false,
  libraryId: payload.libraryId,
  sections: arrayToObject(payload.sections),
  estimationTasks: arrayToObject(payload.estimations),
});

const createSectionSuccessReducer = (state, { payload }) => ({
  ...state,
  error: null,
  isSectionCreateRequest: false,
  sections: {
    ...state.sections,
    [payload._id]: {
      ...payload,
      isEditing: true,
    },
  },
});

const updateSectionSuccessReducer = (state, { payload }) => ({
  ...state,
  error: null,
  sections: {
    ...state.sections,
    [payload._id]: {
      ...state.sections[payload._id],
      ...payload,
    },
  },
});

const deleteSectionSuccessReducer = (state, { payload }) => ({
  ...state,
  error: null,
  sections: R.dissoc(payload._id, state.sections),
});

const createEstimationSuccessReducer = (state, { payload: estimationTask }) => {
  // if we create subitem we need to reset parent estimationTask data
  if (estimationTask.parentId) {
    const parentEstimation = R.pathOr(
      {},
      ['estimationTasks', estimationTask.parentId],
      state
    );
    return {
      ...state,
      isEstimationTaskCreateRequest: false,
      estimationTasks: {
        ...state.estimationTasks,
        [estimationTask._id]: estimationTask,
        [parentEstimation._id]: {
          ...parentEstimation,
          likely: 0,
          average: 0,
          optimistic: 0,
          pessimistic: 0,
          design: 0,
          frontend: 0,
          backend: 0,
        },
      },
    };
  }

  return {
    ...state,
    isEstimationTaskCreateRequest: false,
    estimationTasks: {
      ...state.estimationTasks,
      [estimationTask._id]: estimationTask,
    },
  };
};

const updateEstimationTaskSuccessReducer = (
  state,
  { payload: estimationTask }
) => {
  // this is usage in case we create item with subitems from library
  // in response will be children field with all subitems
  if (estimationTask.children) {
    return {
      ...state,
      error: null,
      estimationTasks: {
        ...state.estimationTasks,
        ...arrayToObject(estimationTask.children),
        [estimationTask._id]: R.omit(['children'], estimationTask),
      },
    };
  }

  return {
    ...state,
    error: null,
    estimationTasks: {
      ...state.estimationTasks,
      [estimationTask._id]: estimationTask,
    },
  };
};

const deleteEstimationTasksBySectionReducer = (
  { estimationTasks, ...state },
  { payload }
) => ({
  ...state,
  estimationTasks: R.omit(
    getChildIdsByParentId(payload._id, 'sectionId', estimationTasks),
    estimationTasks
  ),
});

const deleteEstimationTaskSuccessReducer = (
  state,
  { payload: tasksToDelete }
) => ({
  ...state,
  error: null,
  estimationTasks: R.omit(tasksToDelete, state.estimationTasks),
});

export const libraryReducer = handleActions(
  {
    [fetchLibraryDataRequest]: R.mergeDeepLeft({ isFetching: true }),
    [createLibraryEstimationTaskRequest]: R.mergeDeepLeft({
      isEstimationTaskCreateRequest: true,
    }),
    [createLibrarySectionRequest]: R.mergeDeepLeft({
      isSectionCreateRequest: true,
    }),
    [combineActions(
      fetchLibraryDataFailure,
      createLibrarySectionFailure,
      updateLibrarySectionFailure,
      deleteLibrarySectionFailure,
      createLibraryEstimationTaskFailure,
      updateLibraryEstimationTaskFailure,
      deleteLibraryEstimationTaskFailure
    )]: failureReducer,
    [fetchLibraryDataSuccess]: fetchLibrarySuccessReducer,
    [updateLibrarySectionSuccess]: updateSectionSuccessReducer,
    [createLibrarySectionSuccess]: createSectionSuccessReducer,
    [deleteLibrarySectionSuccess]: deleteSectionSuccessReducer,
    [deleteLibraryEstimationTasksBySection]: deleteEstimationTasksBySectionReducer,
    [createLibraryEstimationTaskSuccess]: createEstimationSuccessReducer,
    [updateLibraryEstimationTaskSuccess]: updateEstimationTaskSuccessReducer,
    [deleteLibraryEstimationTaskSuccess]: deleteEstimationTaskSuccessReducer,
  },
  defaultState
);
