import { createAction } from 'redux-actions';

import { generateApiActions } from './action-helpers';

const types = {
  CREATE_PROJECT: 'CREATE_PROJECT',
  FETCH_PROJECT: 'FETCH_PROJECT',
  FETCH_PROJECTS: 'FETCH_PROJECTS',
  DELETE_PROJECT: 'DELETE_PROJECT',
  FETCH_PROJECT_NAMES: 'FETCH_PROJECT_NAMES',
  CLEAR_PROJECT_REDUX: 'CLEAR_PROJECT_REDUX',
  CLEAR_PROJECT_NAMES_LIST: 'CLEAR_PROJECT_NAMES_LIST',
  FETCH_TECH_STACK: 'FETCH_TECH_STACK',
  ADD_CUSTOMER_INFO: 'ADD_CUSTOMER_INFO',
  UPDATE_CUSTOMER_INFO: 'UPDATE_CUSTOMER_INFO',
  DELETE_CUSTOMER_INFO: 'DELETE_CUSTOMER_INFO',
  CLEAR_SEARCH_DATA: 'CLEAR_SEARCH_DATA',
  FETCH_PROJECT_TEAM: 'FETCH_PROJECT_TEAM',
  ADD_PROJECT_TEAM_MEMBER: 'ADD_PROJECT_TEAM_MEMBER',
  UPDATE_PROJECT_TEAM_MEMBER: 'UPDATE_PROJECT_TEAM_MEMBER',
  DELETE_PROJECT_TEAM_MEMBER: 'DELETE_PROJECT_TEAM_MEMBER',
  FETCH_PROJECT_REQUESTS: 'FETCH_PROJECT_REQUESTS',
  CREATE_PROJECT_COMMENT: 'CREATE_PROJECT_COMMENT',
  FETCH_PROJECT_COMMENTS: 'FETCH_PROJECT_COMMENTS',
  UPDATE_PROJECT_COMMENT: 'UPDATE_PROJECT_COMMENT',
  DELETE_PROJECT_COMMENT: 'DELETE_PROJECT_COMMENT',
  UPDATE_CUSTOMER_FEEDBACK_SETTINGS: 'UPDATE_CUSTOMER_FEEDBACK_SETTINGS',
  FETCH_PROJECT_TEAM_AND_REQUESTS_COUNT: 'FETCH_PROJECT_TEAM_AND_REQUESTS',
};

export const createProjectRequest = createAction(
  `${types.CREATE_PROJECT}_REQUEST`
);
export const createProjectFailure = createAction(
  `${types.CREATE_PROJECT}_FAILURE`
);

export const fetchProjectsRequest = createAction(
  `${types.FETCH_PROJECTS}_REQUEST`
);
export const fetchProjectsSuccess = createAction(
  `${types.FETCH_PROJECTS}_SUCCESS`
);
export const fetchProjectsFailure = createAction(
  `${types.FETCH_PROJECTS}_FAILURE`
);

export const clearSearchData = createAction(`${types.CLEAR_SEARCH_DATA}`);

export const fetchProjectRequest = createAction(
  `${types.FETCH_PROJECT}_REQUEST`
);
export const fetchProjectSuccess = createAction(
  `${types.FETCH_PROJECT}_SUCCESS`
);
export const fetchProjectFailure = createAction(
  `${types.FETCH_PROJECT}_FAILURE`
);

export const deleteProjectRequest = createAction(
  `${types.DELETE_PROJECT}_REQUEST`
);
export const deleteProjectFailure = createAction(
  `${types.DELETE_PROJECT}_FAILURE`
);

export const fetchProjectNamesRequest = createAction(
  `${types.FETCH_PROJECT_NAMES}_REQUEST`
);
export const fetchProjectNamesSuccess = createAction(
  `${types.FETCH_PROJECT_NAMES}_SUCCESS`
);
export const fetchProjectNamesFailure = createAction(
  `${types.FETCH_PROJECT_NAMES}_FAILURE`
);

export const clearProjectNamesList = createAction(
  `${types.CLEAR_PROJECT_NAMES_LIST}_FAILURE`
);

export const fetchTeamAndRequestsCountSuccess = createAction(
  `${types.FETCH_PROJECT_TEAM_AND_REQUESTS_COUNT}_SUCCESS`
);

export const fetchTeamAndRequestsCountFailure = createAction(
  `${types.FETCH_PROJECT_TEAM_AND_REQUESTS_COUNT}_FAILIRE`
);

// Tech Stack
export const fetchTechStackRequest = createAction(
  `${types.FETCH_TECH_STACK}_REQUEST`
);
export const fetchTechStackSuccess = createAction(
  `${types.FETCH_TECH_STACK}_SUCCESS`
);
export const fetchTechStackFailure = createAction(
  `${types.FETCH_TECH_STACK}_FAILURE`
);

export const clearProjectData = createAction(`${types.CLEAR_PROJECT_REDUX}`);

// Customer
export const addCustomerInfoRequest = createAction(
  `${types.ADD_CUSTOMER_INFO}_REQUEST`
);
export const addCustomerInfoSuccess = createAction(
  `${types.ADD_CUSTOMER_INFO}_SUCCESS`
);
export const addCustomerInfoFailure = createAction(
  `${types.ADD_CUSTOMER_INFO}_FAILURE`
);

export const updateCustomerInfoRequest = createAction(
  `${types.UPDATE_CUSTOMER_INFO}_REQUEST`
);
export const updateCustomerInfoSuccess = createAction(
  `${types.UPDATE_CUSTOMER_INFO}_SUCCESS`
);
export const updateCustomerInfoFailure = createAction(
  `${types.UPDATE_CUSTOMER_INFO}_FAILURE`
);

export const deleteCustomerInfoRequest = createAction(
  `${types.DELETE_CUSTOMER_INFO}_REQUEST`
);
export const deleteCustomerInfoSuccess = createAction(
  `${types.DELETE_CUSTOMER_INFO}_SUCCESS`
);
export const deleteCustomerInfoFailure = createAction(
  `${types.DELETE_CUSTOMER_INFO}_FAILURE`
);

// feedback notifications
export const updateCurrentProjectSuccess = createAction(
  `${types.UPDATE_CUSTOMER_FEEDBACK_SETTINGS}_SUCCESS`
);
export const updateCurrentProjectFailure = createAction(
  `${types.UPDATE_CUSTOMER_FEEDBACK_SETTINGS}_FAILURE`
);

// Project Team
export const {
  fetchProjectTeamRequest,
  fetchProjectTeamSuccess,
  fetchProjectTeamFailure,
} = generateApiActions(types.FETCH_PROJECT_TEAM);

export const {
  addProjectTeamMemberRequest,
  addProjectTeamMemberSuccess,
  addProjectTeamMemberFailure,
} = generateApiActions(types.ADD_PROJECT_TEAM_MEMBER);

export const {
  updateProjectTeamMemberRequest,
  updateProjectTeamMemberSuccess,
  updateProjectTeamMemberFailure,
} = generateApiActions(types.UPDATE_PROJECT_TEAM_MEMBER);

export const {
  deleteProjectTeamMemberRequest,
  deleteProjectTeamMemberSuccess,
  deleteProjectTeamMemberFailure,
} = generateApiActions(types.DELETE_PROJECT_TEAM_MEMBER);

// Project Requests
export const {
  fetchProjectRequestsRequest,
  fetchProjectRequestsSuccess,
  fetchProjectRequestsFailure,
} = generateApiActions(types.FETCH_PROJECT_REQUESTS);

// Project Comments
export const createProjectCommentRequest = createAction(
  `${types.CREATE_PROJECT_COMMENT}_REQUEST`
);

export const createProjectCommentSuccess = createAction(
  `${types.CREATE_PROJECT_COMMENT}_SUCCESS`
);

export const createProjectCommentFailure = createAction(
  `${types.CREATE_PROJECT_COMMENT}_FAILURE`
);

export const fetchProjectCommentsRequest = createAction(
  `${types.FETCH_PROJECT_COMMENTS}_REQUEST`
);

export const fetchProjectCommentsSuccess = createAction(
  `${types.FETCH_PROJECT_COMMENTS}_SUCCESS`
);

export const fetchProjectCommentsFailure = createAction(
  `${types.FETCH_PROJECT_COMMENTS}_FAILURE`
);

export const updateProjectCommentRequest = createAction(
  `${types.UPDATE_PROJECT_COMMENT}_REQUEST`
);

export const updateProjectCommentSuccess = createAction(
  `${types.UPDATE_PROJECT_COMMENT}_SUCCESS`
);

export const updateProjectCommentFailure = createAction(
  `${types.UPDATE_PROJECT_COMMENT}_FAILURE`
);

export const deleteProjectCommentRequest = createAction(
  `${types.DELETE_PROJECT_COMMENT}_REQUEST`
);

export const deleteProjectCommentSuccess = createAction(
  `${types.DELETE_PROJECT_COMMENT}_SUCCESS`
);

export const deleteProjectCommentFailure = createAction(
  `${types.DELETE_PROJECT_COMMENT}_FAILURE`
);
