import * as R from 'ramda';

/**
 * getResErrorMsg :: AxiosResponse -> String,
 * AxiosResponse = Object
 */
export const getResErrorMsg = error => {
  const joiMessage = R.pathOr(
    false,
    ['response', 'data', 'details', '0', 'message'],
    error
  );

  if (joiMessage) {
    return joiMessage;
  }

  const message = R.pathOr(
    R.pathOr('Unknown server error', ['message'], error),
    ['response', 'data', 'error', 'message']
  )(error);

  if (message === 'jwt malformed') {
    return 'you were logged out';
  }

  if (message === 'Network Error')
    return 'Something went wrong with connection';

  const responseStatus = R.pathOr(false, ['response', 'status'])(error);

  if (responseStatus > 400) {
    if (
      responseStatus === 404 &&
      message !== 'Request failed with status code 404'
    )
      return 'Not found it';
    if (responseStatus === 400) return 'Bad request, try to inform developers';
  }

  return message;
};
