import * as R from 'ramda';
import { authRequest } from './utils';

/**
 * fetchContacts :: Object -> Promise Error Object
 */
export const fetchUsers = () =>
  authRequest({
    method: 'GET',
    url: '/users',
  }).then(({ data }) => data);

/**
 * fetchUserById :: Object -> Promise Error Object
 */
export const fetchUserById = _id =>
  authRequest({
    method: 'GET',
    url: `/users/${_id}/getuser-by-id`,
  });

/**
 * archiveUserById :: ID -> Promise Error
 */
export const archiveUserById = _id =>
  authRequest({
    method: 'PATCH',
    url: `/users/${_id}/archive-user`,
  });

/**
 * restoreUserById :: ID -> Promise Error
 */
export const restoreUserById = _id =>
  authRequest({
    method: 'PATCH',
    url: `/users/${_id}/restore-user`,
  });

export const changeUserRole = R.curry((_id, data) =>
  authRequest({
    method: 'PATCH',
    url: `/users/${_id}/change-role`,
    data,
  }).then(({ data: { user } }) => user)
);

export const deactivateUserById = _id =>
  authRequest({
    method: 'PATCH',
    url: `/users/${_id}/cancel-invitation`,
  });

export const changeUserAllowedProjects = (userId, allowedProjects) =>
  authRequest({
    method: 'PATCH',
    url: `/users/${userId}/change-allowed-projects`,
    data: {
      allowedProjects,
    },
  }).then(({ data: { user } }) => user);
