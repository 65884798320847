import React from 'react';
import Select from 'react-select';

import { theme } from '../../styles';

const getSelectStyles = props => ({
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: base => ({
    ...base,
    padding: 2,
    content: '""',
  }),
  input: base => ({
    ...base,
    padding: 0,
    height: 20,
  }),
  singleValue: base => ({
    ...base,
    fontSize: 14,
    color: theme.colors.fontSilverPrimary,
  }),
  placeholder: base => ({
    ...base,
    fontSize: 14,
  }),
  control: (base, state) => ({
    ...base,
    width: 170,
    minHeight: 20,
    backgroundColor: state.isDisabled
      ? theme.colors.backgroundLightSilver
      : theme.colors.white,
    borderColor: state.isDisabled
      ? theme.colors.borderLightestSilver
      : props.color || theme.colors.borderOrange,
    '&:hover': {
      borderColor: props.color || theme.colors.borderOrange,
    },
    boxShadow: 'none',
  }),
  option: (base, state) => ({
    ...base,
    fontSize: 14,
    color: state.isSelected
      ? theme.colors.white
      : theme.colors.fontSilverPrimary,
    backgroundColor: state.isSelected
      ? theme.colors.tangerinePrimary
      : theme.colors.white,
    ':hover': {
      backgroundColor: state.isSelected
        ? theme.colors.tangerinePrimary
        : theme.colors.tangerineLight,
    },
    '&:active': {
      backgroundColor: theme.colors.tangerinePrimary,
      color: theme.colors.white,
    },
  }),
  menuList: base => ({
    ...base,
    maxHeight: 200,
  }),
});

const StyledSelect = props => (
  <Select {...props} styles={getSelectStyles(props)} />
);

export default StyledSelect;
