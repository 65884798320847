import React from 'react';
import * as R from 'ramda';
import { format, subDays, subMonths } from 'date-fns';

import { round } from './rounding';

/**
 * listToItems :: [MongoDocument] -> Object
 * MongoDocument = Object
 */
export const listToItems = (array, key = '_id') =>
  R.reduce((acc, x) => R.assoc(x[key], x, acc), {})(array);

/**
 * toggleInArray :: Any -> [Any] -> [Any]
 */
export const toggleInArray = R.curry((item, arr) =>
  R.ifElse(R.contains(item), R.without([item]), R.append(item))(arr)
);

const inputFloatRe = /\d*\.?\d{0,2}/g;

/**
 * parseFloatInput :: String -> String
 */
export const parseFloatInput = (str = '') => {
  const match = str.trim().match(inputFloatRe);
  if (!match || !match[0] || match[0] === '.') {
    return '';
  }
  return match[0];
};

/**
 * getPercentageFromValue :: (Number, Number) -> Number
 */
export const getPercentageFromValue = (value, percent, roundingOptions) =>
  round(value * (percent / 100), roundingOptions);

export const additionalCostsNames = {
  bugFixing: 'Bug fixing',
  management: 'Management',
  codeReview: 'Code review',
  manualTesting: 'Manual testing',
};

export const escapeRegExp = string =>
  string.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

/**
 * Compares two dates
 * When date1 greater - a positive integer will be returned
 * When date2 greater - a negative integer
 * When dates are equal - zero
 * @param {Date} date1 - date to compare
 * @param {Date} date2 - date to compare
 * @returns {Number} - result of dates comparison
 */
const compareDates = (date1, date2) => {
  const date1Number = date1.getTime();
  const date2Number = date2.getTime();

  return date1Number - date2Number;
};

/**
 * Checks the sorting order and update comparisonResult value in order to this value
 * @param {Number} comparisonResult - the difference between two elements
 * @param {Boolean} isAscOrder - use ascending or descending order
 * @returns {Number} - correct comparison result
 */
const respectSortingOrder = (comparisonResult, isAscOrder) =>
  isAscOrder ? comparisonResult : comparisonResult * -1;

export const sortByCreatedAtDate = (firstOldItems = false) => (p1, p2) => {
  const compareDatesResult = compareDates(
    new Date(p1.createdAt),
    new Date(p2.createdAt)
  );

  // dates are equal - sort by ids
  if (compareDatesResult === 0) {
    return respectSortingOrder(p2._id > p1._id, firstOldItems);
  }

  return respectSortingOrder(compareDatesResult, firstOldItems);
};

/**
 * Sum array of objects fields
 * @param {String} prop - property which values will be summed
 * @param {Object[]} items - array of objects
 * @returns {Number} - sum of prop values of objects in the array
 */
export const sumBy = (prop, items) =>
  R.compose(R.sum, R.map(R.prop(prop)))(items);

export const getChildIdsByParentId = (parentId, parentFieldName, itemsMap) =>
  Object.values(itemsMap)
    .filter(item => item[parentFieldName] === parentId)
    .map(({ _id }) => _id);

export const setDocumentTitle = title => {
  if (document.title !== title) {
    document.title = title;
  }
};

export const isEndTrialPeriod = ({ isTrialPeriod = false, trialPeriodTo }) => {
  if (isTrialPeriod) {
    return new Date() >= new Date(trialPeriodTo);
  }

  return false;
};

export const isEndSubscribedPeriod = ({
  isSubscribed = true,
  isCreditPeriod = false,
  isTrialPeriod = false,
  subscribedPeriodTo,
}) => {
  if (!isCreditPeriod && !isSubscribed && !isTrialPeriod) {
    return new Date() >= new Date(subscribedPeriodTo);
  }

  if (!isCreditPeriod && !isTrialPeriod && isSubscribed) {
    return new Date() >= new Date(subscribedPeriodTo);
  }

  return false;
};

export const validateUploadFile = file => {
  const MAX_FILE_SIZE = 50;

  if (file.size / 1024 / 1024 >= MAX_FILE_SIZE) {
    return 'File must be smaller than 50MB!';
  }

  if (file.type.indexOf('video') !== -1) {
    // eslint-disable-next-line quotes
    return "You can't upload video";
  }

  return '';
};

export const getValidateRuleForName = fieldName => ({
  pattern: new RegExp(/^([А-ЯҐЄЁІЇа-яґєёії]|[A-Za-z]|[']|["]|[ ]|[-]){2,}$/),
  message: `${fieldName} must be at least 2 characters long, and contain only letters!`,
});

export const validateOnInputedHtml = value => {
  const pattern = /(<([^>]+)>)/gi;
  return value.replace(pattern, '');
};

export const renameKeys = R.curry((keysMap, obj) =>
  R.reduce(
    (acc, key) => R.assoc(keysMap[key] || key, obj[key], acc),
    {},
    R.keys(obj)
  )
);

/**
 * objToQuery :: Object -> String
 */
export const objToQuery = (obj = {}) =>
  Object.keys(obj)
    .reduce(
      (acc, key) => [...acc, `${key}=${encodeURIComponent(obj[key])}`],
      []
    )
    .join('&');

export const debounce = (inner, ms = 0) => {
  let timer = null;
  let resolves = [];

  const cancel = () => {
    if (timer !== null) {
      clearTimeout(timer);
    }
  };

  const debounced = (...args) => {
    // Run the function after a certain amount of time
    clearTimeout(timer);
    timer = setTimeout(() => {
      // Get the result of the inner function, then apply it to the resolve function of
      // each promise that has been created since the last time the inner function was run
      const result = inner(...args);
      resolves.forEach(r => r(result));
      resolves = [];
    }, ms);

    return new Promise(r => resolves.push(r));
  };

  debounced.cancel = cancel;

  return debounced;
};

export const sortByRank = (a, b) => a.rank - b.rank;

/**
 * Returns a new item rank after sorting based on an previous item rank and the next one
 * @param {array} items - a list of items with rank properties inside
 * @param {string} itemId - ID of the target item
 */
export const calculateRank = (items, itemId) => {
  const itemIndex = items.findIndex(({ _id }) => _id === itemId);

  // the idea is to get the previous and the next items' ranks,
  // sum them and divide by 2
  // in this case we will get an unique value that is placed between lower rank and greater one
  const prevItem = items[itemIndex - 1];
  const nextItem = items[itemIndex + 1];

  if (!prevItem && !nextItem) {
    return 0;
  }

  if (!prevItem && nextItem) {
    return nextItem.rank - 1;
  }

  if (!nextItem && prevItem) {
    return prevItem.rank + 1;
  }

  return (prevItem.rank + nextItem.rank) / 2;
};

/**
 * Reads a file and returns it's content
 * @param {File} file - a file document
 * @param {boolean} returnDataUrl - should return data url or buffer
 * @param {Function} callback - callback
 */
const readFileAs = (file, returnDataUrl = true, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));

  if (returnDataUrl) {
    reader.readAsDataURL(file);
  } else {
    reader.readAsArrayBuffer(file);
  }
};

export const readFileAsDataUrl = (file, callback) =>
  readFileAs(file, true, callback);
export const readFileAsBuffer = (file, callback) =>
  readFileAs(file, false, callback);

export const withStopPropagation = handle => event => {
  event.stopPropagation();
  handle(event);
};
export const PASSWORD_REGEX = new RegExp(
  /^[0-9a-zA-Z!@#$%^&*()"№;%:?*+-_=,.<>~]{8,}$/
);

export const isElementInViewport = element => {
  const rect = element.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const throttle = (fn, limit, scope) => {
  let waiting = false;

  return (...args) => {
    if (!waiting) {
      const context = scope || this;
      waiting = true;

      fn.apply(context, args);

      setTimeout(() => {
        waiting = false;
      }, limit);
    }
  };
};

export const removeFnByProps = props =>
  Object.keys(props).reduce((acc, key) => {
    if (typeof props[key] !== 'function') {
      return {
        ...acc,
        [key]: props[key],
      };
    }

    return acc;
  }, {});

export const safeTrim = R.compose(R.trim, R.defaultTo(''));

export const getDisplayName = WrappedComponent =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component';

/**
 * Clamps a value between an upper and lower bound
 * @param {Number} min
 * @param {Number} val
 * @param {Number} max
 * @returns {number}
 */
export const clamp = (min, val, max) => Math.max(min, Math.min(val, max));

export const getFormattedChartTooltipLabel = (value, daysAmount) => {
  const DAYS_AMOUNT = {
    TWO_WEEKS: 14,
    ONE_MONTH: 30,
    THREE_MONTHS: 90,
  };

  const subDate = R.cond([
    [
      R.equals(DAYS_AMOUNT.TWO_WEEKS),
      () => subDays(new Date(value), daysAmount),
    ],
    [R.equals(DAYS_AMOUNT.ONE_MONTH), () => subMonths(new Date(value), 1)],
    [R.equals(DAYS_AMOUNT.THREE_MONTHS), () => subMonths(new Date(value), 3)],
    [R.T, () => new Date(value)],
  ])(daysAmount);

  const lastTwoWeeks = format(subDate, 'dd LLL');

  return `${lastTwoWeeks} - ${value}`;
};

export const replaceArrayItem = (array, itemIndex, item) => [
  ...array.slice(0, itemIndex),
  item,
  ...array.slice(itemIndex + 1),
];

export const callFnsInSequence = (...funcs) => (...args) => {
  funcs.forEach(fn => fn && fn(...args));
};

export const makeUpperFirstLetter = s => {
  if (typeof s !== 'string') return '';

  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const arrayToObject = (array, key = '_id') =>
  R.reduce((acc, x) => R.assoc(x[key], x, acc), {})(array);

export const sumObjectsValue = (obj1, obj2) =>
  Object.entries(obj1).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: acc[key] + value }),
    obj2
  );

export const hasFormErrors = form =>
  !R.isEmpty(Object.values(form.getFieldsError()).filter(Boolean));

export const getUrlQuery = search => {
  if (!search) {
    return {};
  }

  const hashes = search.slice(search.indexOf('?') + 1).split('&');

  return hashes.reduce((acc, hash) => {
    const [key, val] = hash.split('=');
    let value = null;

    if (!key) {
      return { ...acc };
    }

    try {
      value = decodeURIComponent(val);
    } catch (e) {
      value = '';
    }

    return { ...acc, [key]: value };
  }, {});
};

export const truncate = (str = '', maxSymbols = 60) => {
  if (str.length > maxSymbols) {
    return `${str.substring(0, maxSymbols)}...`;
  }
  return str;
};

export const numberToFixed = (number, demicals) =>
  number.toFixed(demicals).replace(/[.,]00$/, '');

export const joinArrayWithCircles = arr => {
  if (typeof arr === 'string') {
    return arr;
  }

  if (!arr || arr.length === 0) {
    return null;
  }

  const renderCircleIcon = key => (
    <span key={key}>{` ${String.fromCharCode(9679)} `}</span>
  );

  return arr.reduce((acc, elem) => [acc, renderCircleIcon(elem), elem]);
};
