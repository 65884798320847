import * as R from 'ramda';
import { handleActions } from 'redux-actions';

import {
  fetchOrganizationRequest,
  fetchOrganizationSuccess,
  fetchOrganizationFailure,
  updateOrganizationRequest,
  updateOrganizationSuccess,
  updateOrganizationFailure,
} from '../actions/organization';

const defaultState = {
  settings: {
    name: '',
    website: '',
    address: '',
    logo: '',
    cost: '',
    additionalCosts: [],
  },
  isFetching: false,
  isUpdating: false,
  error: null,
};

const fetchOrganizationSuccessReducer = (
  state,
  { payload: { organization } }
) => ({
  ...state,
  isFetching: false,
  settings: {
    ...state.settings,
    ...R.omit(['createdBy', '__v', 'createdAt', '_id'], organization),
  },
});

const updateOrganizationSuccessReducer = (
  state,
  { payload: { settings } }
) => ({
  ...state,
  isUpdating: false,
  settings,
});

export const organizationReducer = handleActions(
  {
    [fetchOrganizationRequest]: R.mergeDeepLeft({ isFetching: true }),
    [fetchOrganizationSuccess]: fetchOrganizationSuccessReducer,
    [fetchOrganizationFailure]: R.mergeDeepLeft({ isFetching: false }),
    [updateOrganizationRequest]: R.mergeDeepLeft({ isUpdating: true }),
    [updateOrganizationSuccess]: updateOrganizationSuccessReducer,
    [updateOrganizationFailure]: R.mergeDeepLeft({ isUpdating: false }),
  },
  defaultState
);
