import { createAction } from 'redux-actions';

const types = {
  FETCH_LIBRARY: 'FETCH_LIBRARY',
  CREATE_LIBRARY_SECTION: 'CREATE_LIBRARY_SECTION',
  UPDATE_LIBRARY_SECTION: 'UPDATE_LIBRARY_SECTION',
  DELETE_LIBRARY_SECTION: 'DELETE_LIBRARY_SECTION',
  CREATE_LIBRARY_ESTIMATION_TASK: 'CREATE_LIBRARY_ESTIMATION_TASK',
  UPDATE_LIBRARY_ESTIMATION_TASK: 'UPDATE_LIBRARY_ESTIMATION_TASK',
  DELETE_LIBRARY_ESTIMATION_TASK: 'DELETE_LIBRARY_ESTIMATION_TASK',
  DELETE_LIBRARY_ESTIMATIONS_BY_SECTION:
    'DELETE_LIBRARY_ESTIMATIONS_BY_SECTION',
};

export const fetchLibraryDataRequest = createAction(
  `${types.FETCH_LIBRARY}_REQUEST`
);
export const fetchLibraryDataSuccess = createAction(
  `${types.FETCH_LIBRARY}_SUCCESS`
);
export const fetchLibraryDataFailure = createAction(
  `${types.FETCH_LIBRARY}_FAILURE`
);

// estimationTasks
export const createLibraryEstimationTaskRequest = createAction(
  `${types.CREATE_LIBRARY_ESTIMATION_TASK}_REQUEST`
);
export const createLibraryEstimationTaskSuccess = createAction(
  `${types.CREATE_LIBRARY_ESTIMATION_TASK}_SUCCESS`
);
export const createLibraryEstimationTaskFailure = createAction(
  `${types.CREATE_LIBRARY_ESTIMATION_TASK}_FAILURE`
);

export const updateLibraryEstimationTaskRequest = createAction(
  `${types.UPDATE_LIBRARY_ESTIMATION_TASK}_REQUEST`
);
export const updateLibraryEstimationTaskSuccess = createAction(
  `${types.UPDATE_LIBRARY_ESTIMATION_TASK}_SUCCESS`
);
export const updateLibraryEstimationTaskFailure = createAction(
  `${types.UPDATE_LIBRARY_ESTIMATION_TASK}_FAILURE`
);

export const deleteLibraryEstimationTaskRequest = createAction(
  `${types.DELETE_LIBRARY_ESTIMATION_TASK}_REQUEST`
);
export const deleteLibraryEstimationTaskSuccess = createAction(
  `${types.DELETE_LIBRARY_ESTIMATION_TASK}_SUCCESS`
);
export const deleteLibraryEstimationTaskFailure = createAction(
  `${types.DELETE_LIBRARY_ESTIMATION_TASK}_FAILURE`
);

export const deleteLibraryEstimationTasksBySection = createAction(
  types.DELETE_LIBRARY_ESTIMATIONS_BY_SECTION
);

// sections
export const createLibrarySectionRequest = createAction(
  `${types.CREATE_LIBRARY_SECTION}_REQUEST`
);
export const createLibrarySectionSuccess = createAction(
  `${types.CREATE_LIBRARY_SECTION}_SUCCESS`
);
export const createLibrarySectionFailure = createAction(
  `${types.CREATE_LIBRARY_SECTION}_FAILURE`
);

export const updateLibrarySectionSuccess = createAction(
  `${types.UPDATE_LIBRARY_SECTION}_SUCCESS`
);
export const updateLibrarySectionFailure = createAction(
  `${types.UPDATE_LIBRARY_SECTION}_FAILURE`
);

export const deleteLibrarySectionSuccess = createAction(
  `${types.DELETE_LIBRARY_SECTION}_SUCCESS`
);
export const deleteLibrarySectionFailure = createAction(
  `${types.DELETE_LIBRARY_SECTION}_FAILURE`
);
