import { propOr, curry } from 'ramda';

/**
 * @enum {ROUNDING_MODES}
 */
export const ROUNDING_MODES = {
  UP: 'UP',
  HALF_UP: 'HALF_UP',
};

export const defaultRoundingOptions = {
  roundingMode: ROUNDING_MODES.HALF_UP,
  roundToPrecision: 2, // by default we round to two decimal places
};

const roundUp = number => Math.ceil(number);

const roundHalfUp = (number, { roundToPrecision } = defaultRoundingOptions) => {
  const multiplier = 10 ** roundToPrecision;

  // 1. We want round only if necessary.
  //   roundHalfUp(10, { precision: 2 }) should return 10, not 10.00
  // 2. To ensure things like 1.005 round correctly, we add Number.EPSILON
  return Math.round((number + Number.EPSILON) * multiplier) / multiplier;
};

const ROUNDING_FUNCTIONS = {
  [ROUNDING_MODES.UP]: roundUp,
  [ROUNDING_MODES.HALF_UP]: roundHalfUp,
};

/**
 * Round number according to given rounding options
 * @param {Number} number - which should be rounded
 * @param {Object} roundingOptions
 * @param {ROUNDING_MODES} roundingOptions.roundingMode
 * @param {Number} roundingOptions.roundToPrecision - the number of digits
 *   to appear after the decimal point
 * @returns {Number} - rounded number
 */
export const round = curry(
  (number, { roundingMode, roundToPrecision } = defaultRoundingOptions) => {
    const roundingFunction = propOr(roundUp, roundingMode, ROUNDING_FUNCTIONS);

    return roundingFunction(number, { roundToPrecision });
  }
);

export const getConvertedTime = value => {
  const hoursCount = Math.floor(value);
  const minutesCount = parseInt(
    ((value - hoursCount).toFixed(2) * 60).toFixed(0),
    10
  );

  const hoursWord = hoursCount === 1 ? 'hour' : 'hours';
  const minutesWord = minutesCount === 1 ? 'minute' : 'minutes';

  const hoursPart = `${hoursCount} ${hoursWord}`;
  const minutesPart = minutesCount > 0 ? `${minutesCount} ${minutesWord}` : '';

  return `${hoursPart} ${minutesPart}`;
};
