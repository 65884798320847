import { makeUpperFirstLetter } from '../utils';

export const SEARCH_TYPE = {
  TASKS: 'tasks',
  PROJECTS: 'projects',
};

export const SEARCH_TYPE_DATA = [
  {
    key: SEARCH_TYPE.TASKS,
    value: makeUpperFirstLetter(SEARCH_TYPE.TASKS),
  },
  {
    key: SEARCH_TYPE.PROJECTS,
    value: makeUpperFirstLetter(SEARCH_TYPE.PROJECTS),
  },
];

export const SEARCH_ITEM_TYPE = {
  ESTIMATION: 'estimations',
  TASK: 'task',
};
