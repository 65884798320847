import * as R from 'ramda';
import { handleActions } from 'redux-actions';

import {
  createAttachmentsRequest,
  createAttachmentsSuccess,
  createAttachmentsFailure,
  fetchAttachmentsRequest,
  fetchAttachmentsSuccess,
  fetchAttachmentsFailure,
  removeAttachmentRequest,
  removeAttachmentSuccess,
  removeAttachmentFailure,
  deleteAttachmentsByProject,
} from '../actions/attachments';
import { listToItems, getChildIdsByParentId } from '../../utils/general';

const defaultState = {
  items: {},
  isCreateRequest: false,
  isFetchRequest: false,
  isRemoveRequest: false,
};

const createAttachmentsSuccessReducer = (state, { payload }) => ({
  ...state,
  isCreateRequest: false,
  items: {
    ...state.items,
    ...listToItems(payload),
  },
});

const fetchAttachmentsSuccessReducer = (state, { payload }) => ({
  ...state,
  isFetchRequest: false,
  items: {
    ...state.items,
    ...listToItems(payload),
  },
});

const removeAttachmentsSuccessReducer = (state, { payload }) => {
  const { type, rootId, attachmentId } = payload;
  const deletedAttachment = R.pathOr({}, ['items', attachmentId], state);

  if (
    deletedAttachment &&
    deletedAttachment.references &&
    deletedAttachment.references.length > 1
  ) {
    return {
      ...state,
      isRemoveRequest: false,
      items: {
        ...R.pickBy((v, k) => k !== attachmentId, state.items),
        [attachmentId]: {
          ...deletedAttachment,
          references: deletedAttachment.references.filter(
            item => !R.equals(item, { type, rootId })
          ),
        },
      },
    };
  }

  return {
    ...state,
    isRemoveRequest: false,
    items: R.dissoc(attachmentId, state.items),
  };
};

const deleteAttachmentsByProjectReducer = (
  { items, ...state },
  { payload }
) => ({
  ...state,
  items: R.omit(getChildIdsByParentId(payload._id, 'projectId', items), items),
});

export const attachmentsReducer = handleActions(
  {
    [createAttachmentsRequest]: R.mergeDeepLeft({ isCreateRequest: true }),
    [createAttachmentsFailure]: R.mergeDeepLeft({ isCreateRequest: false }),
    [createAttachmentsSuccess]: createAttachmentsSuccessReducer,
    [fetchAttachmentsRequest]: R.mergeDeepLeft({ isFetchRequest: true }),
    [fetchAttachmentsFailure]: R.mergeDeepLeft({ isFetchRequest: false }),
    [fetchAttachmentsSuccess]: fetchAttachmentsSuccessReducer,
    [removeAttachmentRequest]: R.mergeDeepLeft({ isRemoveRequest: true }),
    [removeAttachmentFailure]: R.mergeDeepLeft({ isRemoveRequest: false }),
    [removeAttachmentSuccess]: removeAttachmentsSuccessReducer,
    [deleteAttachmentsByProject]: deleteAttachmentsByProjectReducer,
  },
  defaultState
);
