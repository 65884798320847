import * as R from 'ramda';
import { combineActions, handleActions } from 'redux-actions';

import { SEARCH_TYPE } from '../../constants/search';
import {
  searchProjectsRequest,
  searchProjectsSuccess,
  searchProjectsFailure,
  searchTasksRequest,
  searchTasksSuccess,
  searchTasksFailure,
  changeSearchType,
} from '../actions/search';
import {
  fetchTeamAndRequestsCountFailure,
  fetchTeamAndRequestsCountSuccess,
} from '../actions/projects';

const defaultState = {
  error: null,
  isFetching: false,
  items: [],
  itemsCount: 0,
  searchType: SEARCH_TYPE.PROJECTS,
};

const failureReducer = (state, { payload }) => ({
  ...state,
  error: payload,
  isFetching: false,
});

const searchSuccessReducer = (state, { payload }) => ({
  ...state,
  error: null,
  isFetching: false,
  items: payload.items,
  itemsCount: payload.count,
});

const changeSearchTypeReducer = (_, { payload }) => ({
  ...defaultState,
  searchType: payload,
});

const fetchTeamAndRequestsCountSuccessReducer = (state, { payload }) => ({
  ...state,
  items: state.items.map(item => ({
    ...item,
    requestsCount: payload.requestsCount[item._id],
    teamCount: payload.teamCount[item._id],
  })),
});

export const searchReducer = handleActions(
  {
    [combineActions(
      searchProjectsRequest,
      searchTasksRequest
    )]: R.mergeDeepLeft({ isFetching: true }),
    [combineActions(
      searchProjectsFailure,
      searchTasksFailure,
      fetchTeamAndRequestsCountFailure
    )]: failureReducer,
    [combineActions(
      searchProjectsSuccess,
      searchTasksSuccess
    )]: searchSuccessReducer,
    [changeSearchType]: changeSearchTypeReducer,
    [fetchTeamAndRequestsCountSuccess]: fetchTeamAndRequestsCountSuccessReducer,
  },
  defaultState
);
