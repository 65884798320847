import { mergeDeepLeft } from 'ramda';
import { combineActions, handleActions } from 'redux-actions';

import {
  fetchProjectFailure,
  fetchProjectRequest,
  fetchProjectSuccess,
  fetchLabelsRequest,
  fetchLabelsSuccess,
  fetchLabelsFailure,
} from '../../actions/autopilot';

const defaultState = {
  labels: [],
  projectId: null,
  projectInfo: null,
  isFetchingProject: false,
};

const fetchProjectSuccessReducer = (state, { payload }) => ({
  ...state,
  isFetchingProject: false,
  projectId: payload._id,
  projectInfo: payload,
});

const fetchLabelsSuccessReducer = (state, { payload }) => ({
  ...state,
  isFetchingProject: false,
  labels: payload,
});

export const autopilotProjectReducer = handleActions(
  {
    [fetchProjectSuccess]: fetchProjectSuccessReducer,
    [fetchLabelsSuccess]: fetchLabelsSuccessReducer,
    [combineActions(fetchProjectRequest, fetchLabelsRequest)]: mergeDeepLeft({
      isFetchingProject: true,
    }),
    [combineActions(fetchProjectFailure, fetchLabelsFailure)]: mergeDeepLeft({
      isFetchingProject: false,
    }),
  },
  defaultState
);
