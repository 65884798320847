import React from 'react';
import { string, any } from 'prop-types';

const stopPropagation = e => e.stopPropagation();

export const StopPropagation = ({ className, children }) => (
  <div
    className={className}
    onClick={stopPropagation}
    onDoubleClick={stopPropagation}
  >
    {children}
  </div>
);

StopPropagation.propTypes = {
  className: string,
  children: any,
};

StopPropagation.defaultProps = {
  className: '',
  children: null,
};
