import { apikoApi, authRequest } from './utils';

export const fetchFeedback = feedbackId =>
  apikoApi({
    method: 'GET',
    url: `/feedbacks/${feedbackId}`,
  }).then(({ data }) => data);

export const postFeedback = (feedbackId, answers) =>
  apikoApi({
    method: 'POST',
    url: `/feedbacks/${feedbackId}`,
    data: answers,
  }).then(({ data: feedback }) => feedback);

export const fetchFeedbackIssues = (feedbackId, page) =>
  apikoApi({
    method: 'GET',
    url: `/feedbacks/${feedbackId}/issues`,
    params: { page },
  }).then(({ data }) => data);

export const fetchProjectFeedbacks = (projectId, type) =>
  authRequest({
    method: 'GET',
    url: `/feedbacks/list/${projectId}?type=${type}`,
  }).then(({ data }) => data);

export const updateCustomerFeedbackSettings = (
  projectId,
  customerId,
  isFeedbackEnabled
) =>
  authRequest({
    method: 'PATCH',
    url: `/feedbacks/customerNotification/${projectId}`,
    data: {
      _id: customerId,
      isFeedbackEnabled,
    },
  }).then(({ data: { project } }) => project);

export const updateFeedbackClosedTasksPercentage = (projectId, percentage) =>
  authRequest({
    method: 'PATCH',
    url: `/feedbacks/percentage/${projectId}`,
    data: {
      percentage,
    },
  }).then(({ data: { project } }) => project);
