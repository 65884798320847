import { apikoApi } from './utils';
import { isUnauthorizedError } from '../utils';

/**
 * login :: Object -> Promise Error _
 */
export const login = ({ email, password }) =>
  apikoApi({
    method: 'POST',
    url: '/auth/login',
    data: { email, password },
  })
    .then(({ data: { token, userId } }) => {
      localStorage.setItem('token', token);
      localStorage.setItem('userId', userId);
    })
    .catch(error => {
      const errorMessage = isUnauthorizedError(error)
        ? 'Invalid email or password'
        : 'Server error';
      return Promise.reject(new Error(errorMessage));
    });

export const restorePassword = data =>
  apikoApi({
    method: 'POST',
    url: '/auth/restore-password',
    data,
  });
