import * as R from 'ramda';
import { combineActions, handleActions } from 'redux-actions';
import {
  fetchProjectRequest,
  fetchProjectSuccess,
  fetchProjectFailure,
  clearProjectData,
  // TODO: add loading state
  // fetchProjectNamesRequest,
  fetchProjectNamesSuccess,
  fetchProjectNamesFailure,
  clearProjectNamesList,
  fetchTechStackRequest,
  fetchTechStackSuccess,
  fetchTechStackFailure,
  addCustomerInfoRequest,
  updateCustomerInfoRequest,
  deleteCustomerInfoRequest,
  addCustomerInfoFailure,
  updateCustomerInfoFailure,
  deleteCustomerInfoFailure,
  fetchProjectsRequest,
  fetchProjectsSuccess,
  fetchProjectsFailure,
  deleteProjectRequest,
  deleteProjectFailure,
  createProjectRequest,
  createProjectFailure,
  clearSearchData,
  fetchProjectTeamRequest,
  fetchProjectTeamSuccess,
  fetchProjectTeamFailure,
  addProjectTeamMemberRequest,
  addProjectTeamMemberSuccess,
  addProjectTeamMemberFailure,
  deleteProjectTeamMemberRequest,
  deleteProjectTeamMemberFailure,
  deleteProjectTeamMemberSuccess,
  updateProjectTeamMemberRequest,
  updateProjectTeamMemberSuccess,
  updateProjectTeamMemberFailure,
  fetchProjectRequestsRequest,
  fetchProjectRequestsSuccess,
  fetchProjectRequestsFailure,
  createProjectCommentRequest,
  createProjectCommentSuccess,
  createProjectCommentFailure,
  fetchProjectCommentsRequest,
  fetchProjectCommentsSuccess,
  fetchProjectCommentsFailure,
  deleteProjectCommentRequest,
  deleteProjectCommentSuccess,
  deleteProjectCommentFailure,
  updateProjectCommentRequest,
  updateProjectCommentSuccess,
  updateProjectCommentFailure,
  updateCurrentProjectSuccess,
  updateCurrentProjectFailure,
  fetchTeamAndRequestsCountSuccess,
  fetchTeamAndRequestsCountFailure,
} from '../actions/projects';

const defaultState = {
  error: null,
  isFetching: false,
  isDeleting: false,
  isTechStackFetching: false,
  isProjectTeamFetching: false,
  isProjectRequestsFetching: false,
  isUpdatingProject: false,
  isProjectCommentFetching: false,
  items: [],
  // count of all projects by state
  itemsCount: {},
  currentItem: {},
  // list of project's names (in case we need to move/copy estimation inside another project)
  namesList: [],
  stacks: [],
};

const failureReducer = (state, { payload }) => ({
  ...state,
  error: payload,
  isFetching: false,
  isDeleting: false,
  isTechStackFetching: false,
  isProjectTeamFetching: false,
  isProjectRequestsFetching: false,
  isUpdatingProject: false,
  isProjectCommentFetching: false,
});

const fetchProjectsSuccessReducer = (state, { payload }) => ({
  ...state,
  error: null,
  isFetching: false,
  isDeleting: false,
  items: payload.projects,
  itemsCount: payload.count,
});

const fetchProjectSuccessReducer = (state, { payload }) => ({
  ...state,
  error: null,
  isFetching: false,
  isUpdatingProject: false,
  currentItem: {
    ...payload,
    team: state.currentItem?.team || [],
    teamMembersCount: state.currentItem?.teamMembersCount || 0,
    projectComments: state.currentItem?.projectComments || [],
  },
});

const clearProjectDataReducer = state => ({
  ...state,
  currentItem: {},
});

const fetchProjectNamesSuccessReducer = (state, { payload }) => ({
  ...state,
  error: null,
  isFetching: false,
  namesList: payload,
});

const clearProjectNamesListReducer = state => ({
  ...state,
  namesList: [],
});

const fetchTechStackSuccessReducer = (state, { payload }) => ({
  ...state,
  error: null,
  isTechStackFetching: false,
  stacks: payload,
});

const clearSearchDataReducer = state => ({
  ...state,
  error: null,
  items: [],
  itemsCount: {},
});

const fetchProjectTeamSuccessReducer = (state, { payload }) => ({
  ...state,
  error: null,
  isProjectTeamFetching: false,
  currentItem: {
    ...state.currentItem,
    team: payload,
    teamMembersCount: payload.length || 0,
  },
});

const deleteProjectTeamMemberSuccessReducer = (state, { payload }) => ({
  ...state,
  error: null,
  isProjectTeamFetching: false,
  currentItem: {
    ...state.currentItem,
    team: state.currentItem.team.filter(({ _id }) => _id !== payload),
    teamMembersCount: state.currentItem.teamMembersCount - 1,
  },
});

const updateProjectTeamMemberSuccessReducer = (state, { payload }) => ({
  ...state,
  error: null,
  isUpdatingProject: false,
  currentItem: {
    ...state.currentItem,
    team: state.currentItem.team.map(member => {
      if (member._id === payload.teamMemberId)
        return {
          ...member,
          timelines: payload.timelines || [],
          isResponsibleForProject: payload.isResponsibleForProject,
        };
      return member;
    }),
  },
});

const createProjectCommentSuccessReducer = (state, { payload }) => ({
  ...state,
  isProjectCommentFetching: false,
  currentItem: {
    ...state.currentItem,
    projectComments: payload,
    commentsCount: payload.length,
  },
});

const fetchProjectCommentsSuccessReducer = (state, { payload }) => ({
  ...state,
  isProjectCommentFetching: false,
  currentItem: {
    ...state.currentItem,
    projectComments: payload,
    commentsCount: payload.length,
  },
});

const updateProjectCommentSuccessReducer = (state, { payload }) => ({
  ...state,
  isProjectCommentFetching: false,
  currentItem: {
    ...state.currentItem,
    projectComments: payload,
    commentsCount: payload.length,
  },
});

const deleteProjectCommentSuccessReducer = (state, { payload }) => ({
  ...state,
  isProjectCommentFetching: false,
  currentItem: {
    ...state.currentItem,
    projectComments: payload,
    commentsCount: payload.length,
  },
});

const fetchProjectRequestsSuccessReducer = (state, { payload }) => ({
  ...state,
  error: null,
  isProjectRequestsFetching: false,
  currentItem: {
    ...state.currentItem,
    requests: payload,
    requestsCount: payload.length || 0,
  },
});

const updateCurrentProjectSuccessReducer = (state, { payload }) => ({
  ...state,
  isFetching: false,
  erro: null,
  currentItem: {
    ...state.currentItem,
    ...payload,
  },
});

const fetchTeamAndRequestsCountSuccessReducer = (state, { payload }) => ({
  ...state,
  items: state.items.map(item => ({
    ...item,
    requestsCount: payload.requestsCount[item._id],
    teamCount: payload.teamCount[item._id],
  })),
});

export const projectsReducer = handleActions(
  {
    [combineActions(
      createProjectRequest,
      fetchProjectRequest,
      fetchProjectsRequest
    )]: R.mergeDeepLeft({ isFetching: true }),
    [combineActions(
      fetchProjectFailure,
      fetchProjectsFailure,
      fetchTeamAndRequestsCountFailure,
      fetchProjectNamesFailure,
      fetchTechStackFailure,
      addCustomerInfoFailure,
      updateCustomerInfoFailure,
      deleteCustomerInfoFailure,
      deleteProjectFailure,
      createProjectFailure,
      updateProjectTeamMemberFailure,
      updateCurrentProjectFailure,
      createProjectCommentFailure,
      fetchProjectCommentsFailure,
      updateProjectCommentFailure,
      deleteProjectCommentFailure
    )]: failureReducer,
    [fetchProjectsSuccess]: fetchProjectsSuccessReducer,
    [fetchTeamAndRequestsCountSuccess]: fetchTeamAndRequestsCountSuccessReducer,
    [fetchTechStackRequest]: R.mergeDeepLeft({ isTechStackFetching: true }),
    [deleteProjectRequest]: R.mergeDeepLeft({ isDeleting: true }),
    [fetchProjectSuccess]: fetchProjectSuccessReducer,
    [clearProjectData]: clearProjectDataReducer,
    [fetchProjectNamesSuccess]: fetchProjectNamesSuccessReducer,
    [clearProjectNamesList]: clearProjectNamesListReducer,
    [fetchTechStackSuccess]: fetchTechStackSuccessReducer,
    [combineActions(
      addCustomerInfoRequest,
      updateCustomerInfoRequest,
      deleteCustomerInfoRequest,
      updateProjectTeamMemberRequest
    )]: R.mergeDeepLeft({ isUpdatingProject: true }),
    [clearSearchData]: clearSearchDataReducer,

    // Project Team
    [combineActions(
      fetchProjectTeamRequest,
      addProjectTeamMemberRequest,
      deleteProjectTeamMemberRequest
    )]: R.mergeDeepLeft({ isProjectTeamFetching: true }),
    [fetchProjectTeamSuccess]: fetchProjectTeamSuccessReducer,
    [fetchProjectTeamFailure]: R.mergeDeepLeft({
      isProjectTeamFetching: false,
    }),
    [deleteProjectTeamMemberSuccess]: deleteProjectTeamMemberSuccessReducer,
    [updateProjectTeamMemberSuccess]: updateProjectTeamMemberSuccessReducer,

    // Project Requests
    [fetchProjectRequestsRequest]: R.mergeDeepLeft({
      isProjectRequestsFetching: true,
    }),
    [fetchProjectRequestsSuccess]: fetchProjectRequestsSuccessReducer,
    [combineActions(
      fetchProjectRequestsFailure,
      addProjectTeamMemberSuccess,
      addProjectTeamMemberFailure,
      deleteProjectTeamMemberFailure
    )]: R.mergeDeepLeft({
      isProjectRequestsFetching: false,
    }),

    // Project Comments
    [combineActions(
      createProjectCommentRequest,
      fetchProjectCommentsRequest,
      deleteProjectCommentRequest,
      updateProjectCommentRequest
    )]: R.mergeDeepLeft({
      isProjectCommentFetching: true,
    }),
    [createProjectCommentSuccess]: createProjectCommentSuccessReducer,
    [fetchProjectCommentsSuccess]: fetchProjectCommentsSuccessReducer,
    [updateProjectCommentSuccess]: updateProjectCommentSuccessReducer,
    [deleteProjectCommentSuccess]: deleteProjectCommentSuccessReducer,
    [updateCurrentProjectSuccess]: updateCurrentProjectSuccessReducer,
  },
  defaultState
);
