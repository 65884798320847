import { createAction } from 'redux-actions';

export const fetchUsersRequest = createAction('FETCH_USERS_REQUEST');
export const fetchUserByIdRequest = createAction('FETCH_USER_BY_ID_REQUEST');
export const fetchUsersSuccess = createAction('FETCH_USERS_SUCCESS');
export const fetchUsersFailure = createAction('FETCH_USERS_FAILURE');

export const archiveUser = createAction('ARCHIVE_USER');
export const restoreUser = createAction('RESTORE_USER');
export const deactivateUser = createAction('DELETE_USER'); // TODO: rename DELETE to DEACTIVATE
export const changeUserRole = createAction('CHANGE_USER_ROLE');

export const updateUser = createAction('UPDATE_USER');
export const clearUsers = createAction('CLEAR_USERS');

export const changeAllowedProjectsRequest = createAction(
  'CHANGE_ALLOWED_PROJECTS_REQUEST'
);
export const changeAllowedProjectsSuccess = createAction(
  'CHANGE_ALLOWED_PROJECTS_SUCCESS'
);
export const changeAllowedProjectsFailure = createAction(
  'CHANGE_ALLOWED_PROJECTS_FAILURE'
);
