import { updateProject } from '../projects';
import { authRequest } from './utils';

/**
 * fetchTalents :: () -> Promise Error Object
 */
export const fetchTalents = () =>
  authRequest({
    method: 'GET',
    url: '/public/candidates?type=employee&all=true',
  }).then(({ data }) => data);

/**
 * fetchProjectTalents :: (String) -> Promise Error Object
 */
export const fetchProjectTalents = projectId =>
  authRequest({
    method: 'GET',
    url: `/project-team/${projectId}`,
  }).then(({ data }) => data);

/**
 * addTalentToProject :: (String, Object) -> Promise Error Object
 */
export const addTalentToProject = (employeeId, projectData) =>
  authRequest({
    method: 'POST',
    url: `/employees/${employeeId}/add-project`,
    data: projectData,
  }).then(({ data }) => {
    updateProject(projectData._id, { updatedAt: new Date() });
    return data;
  });

/**
 * updateProjectInfo :: (String, Object) -> Promise Error Object
 */
export const updateProjectInfo = (projectId, projectData) =>
  authRequest({
    method: 'PATCH',
    url: `/employees/update-project-info/${projectId}`,
    data: projectData,
  })
    .then(() => updateProject(projectId, { updatedAt: new Date() }))
    .catch(e => {
      console.log('Failed to update Project Info', e);
    });

/**
 * updateProjectTalent :: (String, String, Object) -> Promise Error Object
 */
export const updateProjectTalent = (employeeId, projectId, projectData) =>
  authRequest({
    method: 'PATCH',
    url: `/employees/${employeeId}/edit-project/${projectId}`,
    data: projectData,
  }).then(({ data }) => {
    updateProject(projectId, { updatedAt: new Date() });
    return data;
  });

/**
 * deleteProjectTalent :: (String, String) -> Promise Error Object
 */
export const deleteProjectTalent = (employeeId, projectId) =>
  authRequest({
    method: 'DELETE',
    url: `/employees/${employeeId}/remove-project/${projectId}`,
  }).then(() => updateProject(projectId, { updatedAt: new Date() }));

/**
 * validateProjectTimeline :: (String, Object, Object) -> Promise Error Object
 */
export const validateProjectTimeline = (employeeId, params, data) =>
  authRequest({
    method: 'POST',
    url: `/employees/${employeeId}/validate-timeline`,
    params,
    data,
  });

export const fetchProjectTeamCount = projectIds =>
  authRequest({
    method: 'GET',
    url: `/employees/projects-team-members-count?projects=${projectIds}`,
  }).then(({ data }) => data);
