import { PROGRESS_STATUSES } from '../components/progress';

export const theme = {
  colors: {
    white: '#ffffff',
    green: '#27ae60',
    backgroundLightBlue: '#f9fdfe',
    backgroundAliceBlue: '#e6f7ff',
    backgroundLightSilver: '#f8f9fB',
    backgroundDarkSilver: 'rgba(114, 111, 111, 0.65)',
    backgroundDark: '#485465',
    backgroundLightDark: '#868f9d',
    backgroundSilver: '#d5d6d6',
    backgroundSilverDark: '#e1ecef',
    backgroundGray: '#fafafa',
    tangerinePrimary: '#ff9900',
    tangerineSecond: '#fd6f0f',
    tangerineLight: '#FEF1E6',
    lightOrange: '#FCD2B5',
    red: '#f5203a',
    fontNavigation: '#595959',
    fontTangerine: '#fd740d',
    fontLightBlack: '#1B1B1B',
    fontBlack: '#131313',
    fontMirage: '#1B1B2F',
    fontGray: '#808080',
    fontDark: '#485465',
    fontDarkBlue: '#183d50',
    fontSilverDark: '#4a4a4a',
    fontSilverPrimary: '#626262',
    fontSilverLight: '#999999',
    fontSmoke: '#aeaeae',
    fontSilver: '#adb6bb',
    fontSilverLighter: 'rgba(27, 27, 27, 0.4)',
    fontLinkHover: '#40a9ff',
    borderGray: '#E9EBF2',
    borderDarkBlue: '#183d50',
    borderSilverDark: '#979797',
    borderSilver: '#bac1c6',
    borderOrange: '#f5a623',
    borderLightSilver: '#e8e8e8',
    borderLightestSilver: 'rgba(27, 27, 27, 0.1)',
    iconGrey: 'rgba(27, 27, 27, 0.5)',
    shadowSilver: 'rgba(0, 0, 0, 0.16)',
    shadowBlue: '#80BFFE',
    secondaryDark: '#808080',
    whisper: '#f1eded',
    antique: '#ffe4cc',
    darkColor: '#333',
    table: {
      title: '#707070',
      hoverBg: '#E8F3FE',
      silverLighter: 'rgba(27, 27, 27, 0.6)',
      mercury: '#E6E6E6',
      shadow: 'rgba(0, 0, 0, 0.15)',
      activeCellBg: '#E8F3FE',
      activeCellBr: '#80BFFE',
    },
    scrollbar: {
      track: '#f1f1f1',
      thumb: '#f5a623',
      thumbHover: '#de9720',
    },
    progressbar: {
      [PROGRESS_STATUSES.NORMAL]: '#09b7d3',
      [PROGRESS_STATUSES.DANGER]: '#ED8172',
      [PROGRESS_STATUSES.WARNING]: '#FFD654',
    },
    tooltip: {
      labelDark: '#a5a6b5',
      labelPrimary: '#d1d2e1',
      labelLight: '#e7e8f9',
    },
    requestType: {
      webRequest: '#725ca4',
      upwork: '#04aee6',
      reference: '#6cd8d9',
      existingCustomer: '#00a085',
      default: '#a4a4a4',
    },
    requestTypeFill: {
      existingCustomer: 'rgba(0, 221, 188, 0.3)',
    },
    requestStatus: {
      lead: '#e67e22',
      initialContact: '#4887CA',
      bACommunication: '#1B1464',
      lTCommunication: '#12CBC4',
      customer: '#27ae60',
      lost: '#e74c3c',
      externalOffices: '#f1c40f',
      garbage: '#95a5a6',
      notRelevant: '#7f8c8d',
      delivered: '#8e44ad',
      default: '#e67e22',
    },
    clientCharts: {
      customer: '#fd740d',
      lead: '#d8d8d8',
    },
  },
  fonts: {
    primary: 'Rubik',
  },
};
