import styled from 'styled-components';
import { Input } from 'antd';

export const SearchInput = styled(Input.Search)`
  width: 290px;
  margin-right: 35px;

  .ant-input-search-icon {
    color: ${({ theme }) => theme.colors.fontSilverLighter};
    font-size: 18px;
    font-weight: 700;
  }

  :hover .ant-input:not(.ant-input-disabled) {
    border-color: ${({ theme }) => theme.colors.fontTangerine};
  }

  input {
    border-radius: 30px;
    padding: 10px 16px;
    height: 38px;
    border: 1px solid ${({ theme }) => theme.colors.borderLightestSilver};

    :focus {
      border-color: ${({ theme }) => theme.colors.fontTangerine};
      box-shadow: 0 0 0 2px rgba(253, 116, 13, 0.2);
    }

    ::placeholder {
      color: ${({ theme }) => theme.colors.fontSilverLighter};
      font-size: 14px;
    }
  }
`;
