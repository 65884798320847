export const DEFAULT_PAGE_TITLE = 'Apiko App';

export const ROUTES = {
  HOME: '/',
  SIGN_IN: '/sign-in',
  LIBRARY: '/library',
  PROJECTS: '/projects',
  SEARCH: '/search',
  ORGANIZATION_SETTINGS: '/organization-settings',
  NOT_FOUND: '/not-found',
  EDIT_USER_DATA: '/profile-settings',
  PROJECT_DETAILS: '/projects/:projectId',
  INVITE_USER: '/invite-user/:invitationSecret',
  ESTIMATION: '/estimations/:estimationId',
  ESTIMATION_PREVIEW: '/p/:previewToken',
  ESTIMATION_EXPORT: '/estimation-export/:estimationId',
  FEEDBACK: '/feedbacks/:feedbackId',
};

export const ROUTE_NAMES = {
  SIGN_IN: 'Sign in',
  LIBRARY: 'Library',
  PROJECTS: 'Projects',
  SEARCH: 'Search',
  ORGANIZATION_SETTINGS: 'Organization settings',
  NOT_FOUND: 'Not found',
  EDIT_USER_DATA: 'Profile settings',
  INVITE_USER: 'Invite user',
  FEEDBACK: 'Feedback',
  // this names will be taken from redux
  // because we need to show current project/estimation name
  PROJECT_DETAILS: 'PROJECT_DETAILS',
  ESTIMATION: 'ESTIMATION_DETAILS',
  ESTIMATION_PREVIEW: 'ESTIMATION_PREVIEW',
};
