import { createAction } from 'redux-actions';

const types = {
  FETCH_TIME: 'AUTOPILOT/FETCH_TIME',
  FETCH_TEAM: 'AUTOPILOT/FETCH_TEAM',
  FETCH_ISSUES: 'AUTOPILOT/FETCH_ISSUES',
  FETCH_ASSIGNEE_ISSUES: 'AUTOPILOT/FETCH_ASSIGNEE_ISSUES',
  REMOVE_ISSUES: 'AUTOPILOT/REMOVE_ISSUES',
  REMOVE_ASSIGNEE_ISSUES: 'AUTOPILOT/REMOVE_ASSIGNEE_ISSUES',
  REFRESH_PROJECT: 'AUTOPILOT/REFRESH_PROJECT',
  UPDATE_ISSUE: 'AUTOPILOT/UPDATE_ISSUE',
  FETCH_PROJECT: 'AUTOPILOT/FETCH_PROJECT',
  UPDATE_PROJECT: 'AUTOPILOT/UPDATE_PROJECT',
  FETCH_TIME_CHARTS: 'AUTOPILOT/FETCH_TIME_CHARTS',
  HANDLE_DATE_FROM_GRAPH: 'AUTOPILOT/HANDLE_DATE_FROM_GRAPH',
  FETCH_LABELS: 'AUTOPILOT/FETCH_LABELS',
  FETCH_HUBSTAFF_PROJECTS: 'FETCH_HUBSTAFF_PROJECTS',
  FETCH_CONNECT_HUBSTAFF_PROJECT: 'FETCH_CONNECT_HUBSTAFF_PROJECT',

  FETCH_INTEGRATIONS: 'FETCH_INTEGRATIONS',
  FETCH_INITIALIZE_HUBSTAFF_INTEGRATION:
    'FETCH_INITIALIZE_HUBSTAFF_INTEGRATION',
  FETCH_UNCONNECT_HUBSTAFF_INTEGRATION: 'FETCH_UNCONNECT_HUBSTAFF_INTEGRATION',
};

export const refreshProjectsRequest = createAction(
  `${types.REFRESH_PROJECT}_REQUEST`
);
export const refreshAutopilotProjectsSuccess = createAction(
  `${types.REFRESH_PROJECT}_SUCCESS`
);

export const fetchProjectRequest = createAction(
  `${types.FETCH_PROJECT}_REQUEST`
);
export const fetchProjectSuccess = createAction(
  `${types.FETCH_PROJECT}_SUCCESS`
);
export const fetchProjectFailure = createAction(
  `${types.FETCH_PROJECT}_FAILURE`
);

export const fetchIssuesRequest = createAction(`${types.FETCH_ISSUES}_REQUEST`);
export const fetchIssuesSuccess = createAction(`${types.FETCH_ISSUES}_SUCCESS`);
export const fetchIssuesFailure = createAction(`${types.FETCH_ISSUES}_FAILURE`);

export const fetchAssigneeIssuesSuccess = createAction(
  `${types.FETCH_ASSIGNEE_ISSUES}_SUCCESS`
);

export const fetchTeamRequest = createAction(`${types.FETCH_TEAM}_REQUEST`);
export const fetchTeamSuccess = createAction(`${types.FETCH_TEAM}_SUCCESS`);
export const fetchTeamFailure = createAction(`${types.FETCH_TEAM}_FAILURE`);

export const updateIssue = createAction(types.UPDATE_ISSUE);

export const updateAutopilotProject = createAction(types.UPDATE_PROJECT);

export const handleDateFromGraph = createAction(types.HANDLE_DATE_FROM_GRAPH);

export const fetchIssuesTimeSuccess = createAction(types.FETCH_TIME);

export const fetchHubstaffProjectsRequest = createAction(
  `${types.FETCH_HUBSTAFF_PROJECTS}_REQUEST`
);
export const fetchHubstaffProjectsSuccess = createAction(
  `${types.FETCH_HUBSTAFF_PROJECTS}_SUCCESS`
);
export const fetchHubstaffProjectsFailure = createAction(
  `${types.FETCH_HUBSTAFF_PROJECTS}_FAILURE`
);

export const fetchConnectHubstaffProjectRequest = createAction(
  `${types.FETCH_CONNECT_HUBSTAFF_PROJECT}_REQUEST`
);
export const fetchConnectHubstaffProjectSuccess = createAction(
  `${types.FETCH_CONNECT_HUBSTAFF_PROJECT}_SUCCESS`
);
export const fetchConnectHubstaffProjectFailure = createAction(
  `${types.FETCH_CONNECT_HUBSTAFF_PROJECT}_FAILURE`
);

export const fetchIntegrationsRequest = createAction(
  `${types.FETCH_INTEGRATIONS}_REQUEST`
);
export const fetchIntegrationsSuccess = createAction(
  `${types.FETCH_INTEGRATIONS}_SUCCESS`
);
export const fetchIntegrationsFailure = createAction(
  `${types.FETCH_INTEGRATIONS}_FAILURE`
);

export const fetchInitializeHubstaffIntegration = createAction(
  `${types.FETCH_INITIALIZE_HUBSTAFF_INTEGRATION}`
);

export const fetchUnconnectHubstaffIntegration = createAction(
  `${types.FETCH_UNCONNECT_HUBSTAFF_INTEGRATION}`
);

export const removeIssues = createAction(`${types.REMOVE_ISSUES}`);
export const removeAssigneeIssues = createAction(
  `${types.REMOVE_ASSIGNEE_ISSUES}`
);

export const fetchLabelsRequest = createAction(`${types.FETCH_LABELS}_REQUEST`);
export const fetchLabelsSuccess = createAction(`${types.FETCH_LABELS}_SUCCESS`);
export const fetchLabelsFailure = createAction(`${types.FETCH_LABELS}_FAILURE`);
