import * as R from 'ramda';
import { handleActions } from 'redux-actions';

import {
  updateProfileRequest,
  updateProfileEndRequest,
  updateProfileSuccess,
} from '../actions/profile-settings';

const defaultState = {
  isFetching: false,
};

export const profileSettingsReducers = handleActions(
  {
    [updateProfileRequest]: R.mergeDeepLeft({ isFetching: true }),
    [updateProfileSuccess]: R.mergeDeepLeft({ isFetching: false }),
    [updateProfileEndRequest]: R.mergeDeepLeft({ isFetching: false }),
  },
  defaultState
);
