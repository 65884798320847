import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { enableBatching } from 'redux-batched-actions';
import thunkMiddleware from 'redux-thunk';

import history from './history';
import { isProd } from '../api/utils';
import { reducers } from './reducers';

const composeEnhancers =
  (!isProd() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store = createStore(
  enableBatching(reducers),
  {},
  composeEnhancers(applyMiddleware(thunkMiddleware, routerMiddleware(history)))
);
