import * as R from 'ramda';

import { estimationAverage, estimationTotal } from './estimation';
import { getPercentageFromValue, listToItems, calculateRank } from './general';
import { round } from './rounding';

export const PROJECT_TABS = {
  DETAIL: 'DETAIL',
  DELIVERABLES: 'DELIVERABLES',
};

export const PROJECTS_LIST_TABS = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
};

export const PROJECT_INPUTS = {
  NAME: 'name',
  DESCRIPTION: 'description',
  CUSTOMER_NAME: 'customer-name',
  CUSTOMER_EMAIL: 'customer-email',
  STATUS: 'status',
  STACK: 'stack',
};

export const ESTIMATION_FEATURES = {
  COST: 'COST',
  PRIORITY: 'PRIORITY',
  COPY_SECTION: 'COPY_SECTION',
  SECTION_TOTAL: 'SECTION_TOTAL',
  ADD_TO_LIBRARY: 'ADD_TO_LIBRARY',
  DELETE_SECTION: 'DELETE_SECTION',
  MILESTONE_FILTER: 'MILESTONE_FILTER',
  EDIT_SECTION_NAME: 'EDIT_SECTION_NAME',
  MILESTONE_SELECTOR: 'MILESTONE_SELECTOR',
  ESTIMATION_DETAILS: 'ESTIMATION_DETAILS',
  ESTIMATION_EDITABLE: 'ESTIMATION_EDITABLE',
  ADD_NEW_SECTION_ITEM: 'ADD_NEW_SECTION_ITEM',
  SHOW_ADDITIONAL_COSTS: 'SHOW_ADDITIONAL_COSTS',
  EDIT_ADDITIONAL_COSTS: 'EDIT_ADDITIONAL_COSTS',
  ADD_NEW_ESTIMATION_ITEM: 'ADD_NEW_ESTIMATION_ITEM',
  ESTIMATION_NAME_AUTOCOMPLETE: 'ESTIMATION_NAME_AUTOCOMPLETE',
};

/**
 * getEstimationFeatures :: Array -> Object
 */
export const getEstimationFeatures = R.compose(
  R.pick(R.__, ESTIMATION_FEATURES),
  R.defaultTo([])
);

/**
 * calculateAdditionalCosts :: (Object, Object) -> Object
 */
export const calculateAdditionalCosts = (
  projectEstimationsSum,
  additionalCosts = {},
  projectEstimationType,
  roundingOptions
) => {
  const {
    likely,
    design,
    backend,
    average,
    frontend,
    optimistic,
    pessimistic,
  } = projectEstimationsSum;
  // const average = estimationAverage(projectEstimationsSum);
  const additionalConstsObj = listToItems(additionalCosts);
  // create estimations like list with additional costs data
  const additionalCostsEstimations = Object.keys(additionalCosts).map(key => {
    const additionalCostItem = additionalCosts[key];
    const additionalConstId = additionalCostItem._id;
    const costsEstimation =
      projectEstimationType === 'average'
        ? {
            likely: getPercentageFromValue(
              likely,
              additionalCostItem.value,
              roundingOptions
            ),
            optimistic: getPercentageFromValue(
              optimistic,
              additionalCostItem.value,
              roundingOptions
            ),
            pessimistic: getPercentageFromValue(
              pessimistic,
              additionalCostItem.value,
              roundingOptions
            ),
          }
        : {
            design: getPercentageFromValue(
              design,
              additionalCostItem.value,
              roundingOptions
            ),
            backend: getPercentageFromValue(
              backend,
              additionalCostItem.value,
              roundingOptions
            ),
            frontend: getPercentageFromValue(
              frontend,
              additionalCostItem.value,
              roundingOptions
            ),
          };

    return {
      _id: additionalConstsObj[additionalConstId].key,
      ...costsEstimation,
      average:
        projectEstimationType === 'average'
          ? estimationAverage(costsEstimation, roundingOptions)
          : estimationTotal(costsEstimation, roundingOptions),
    };
  });

  // calculate total average value of additional costs
  const additionalCostsTotal = additionalCostsEstimations.reduce(
    (memo, additionalCostItem) => memo + additionalCostItem.average,
    0
  );

  const getCostsPerCategory = arr =>
    arr.reduce(
      (acc, item) => ({ ...acc, [item._id]: R.dissoc('_id', item) }),
      {}
    );

  const projectTotal = round(average + additionalCostsTotal, roundingOptions);

  return {
    projectTotal,
    additionalCostsTotal: round(additionalCostsTotal, roundingOptions),
    additionalCostsPerCategory: getCostsPerCategory(additionalCostsEstimations),
  };
};

export const getProjectTotal = (
  estimationsItems,
  additionalCosts,
  projectId,
  projectEstimationType,
  roundingOptions
) => {
  const projectEstimation = estimationsItems.reduce(
    (acc, item) => {
      if (item.projectId === projectId) {
        acc.likely += item.likely;
        acc.design += item.design;
        acc.average += item.average;
        acc.backend += item.backend;
        acc.frontend += item.frontend;
        acc.optimistic += item.optimistic;
        acc.pessimistic += item.pessimistic;
      }

      return acc;
    },
    {
      design: 0,
      likely: 0,
      average: 0,
      backend: 0,
      frontend: 0,
      optimistic: 0,
      pessimistic: 0,
    }
  );
  const { projectTotal } = calculateAdditionalCosts(
    projectEstimation,
    additionalCosts,
    projectEstimationType,
    roundingOptions
  );

  return projectTotal;
};

export const getSortedItemId = R.path(['item', 'dataset', 'id']);

const findItemById = (itemId, items) => R.find(R.propEq('_id', itemId), items);

export const calculateSectionRankOnSort = (
  sections,
  sortedItemIds,
  sortEvent
) => {
  const sortedItemId = getSortedItemId(sortEvent);

  const sortedSections = sortedItemIds.map(sectionId =>
    findItemById(sectionId, sections)
  );

  const sectionToUpdate = sortedSections.find(
    ({ _id }) => _id === sortedItemId
  );

  const rank = calculateRank(sortedSections, sortedItemId);

  return sectionToUpdate.rank === rank ? null : rank;
};

export const getToken = (router, key) =>
  R.path(['location', 'params', key], router) ||
  R.path(['location', 'query', key], router);

export const getProjectId = router =>
  (R.path(['location', 'pathname'], router) || '').split('/').reverse()[0];
