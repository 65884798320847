import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import 'antd/lib/popconfirm/style/css';
import 'antd/lib/message/style/css';
import 'antd/lib/dropdown/style/css';
import 'antd/lib/modal/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/form/style/css';
import 'antd/lib/divider/style/css';
import 'antd/lib/date-picker/style/css';
import 'antd/lib/menu/style/css';
import 'antd/lib/switch/style/css';
import 'antd/lib/auto-complete/style/css';
import 'antd/lib/collapse/style/css';
import 'antd/lib/checkbox/style/css';
import 'antd/lib/pagination/style/css';
import 'antd/lib/spin/style/css';
import 'antd/lib/timeline/style/css';
import 'antd/lib/rate/style/css';
import 'antd/lib/progress/style/css';
import 'antd/lib/input-number/style/css';

import { theme } from './theme';

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  html, body {
    margin: 0;
    height: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    overflow: visible;
    background-color: #F7F9FA;
    color: ${theme.colors.fontSilverPrimary} !important;
    line-height: 1.5;
  }

  * {
    box-sizing : border-box;
    font-family: '${theme.fonts.primary}', sans-serif;
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  .ant-spin-nested-loading > div > .ant-spin {
    max-height: 100%;
  }

  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-dropdown-menu {
    padding: unset;
  }
  .ant-select-dropdown-menu {
    padding: unset;
  }
  .ant-select-dropdown-menu-item {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;
