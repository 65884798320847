import * as R from 'ramda';
import { handleActions, combineActions } from 'redux-actions';

import {
  saveOriginUrl,
  signInRequest,
  signInSuccess,
  signInFailure,
  restorePasswordRequest,
  restorePasswordSuccess,
  restorePasswordFailure,
  logout,
} from '../actions/auth';

const defaultState = {
  originUrl: null,
  isAuthorized: false,
  isFetching: false,
  signInError: null,
};

export const authReducers = handleActions(
  {
    [saveOriginUrl]: (state, { payload }) => ({
      ...state,
      originUrl: payload,
    }),
    [combineActions(signInRequest, restorePasswordRequest)]: R.mergeDeepLeft({
      isFetching: true,
      signInError: null,
    }),
    [signInSuccess]: R.mergeDeepLeft({ isFetching: false, isAuthorized: true }),
    [signInFailure]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      isAuthorized: false,
      signInError: payload,
    }),
    [combineActions(
      restorePasswordSuccess,
      restorePasswordFailure
    )]: R.mergeDeepLeft({ isFetching: false }),
    [logout]: () => defaultState,
  },
  defaultState
);
