import { mergeLeft } from 'ramda';
import { handleActions } from 'redux-actions';
import {
  fetchHubstaffProjectsSuccess,
  fetchHubstaffProjectsFailure,
  fetchHubstaffProjectsRequest,
  fetchConnectHubstaffProjectSuccess,
  fetchConnectHubstaffProjectRequest,
  fetchConnectHubstaffProjectFailure,
} from '../../actions/autopilot';

const defaultState = {
  connectedProjects: [],
  projects: [],
  isFetching: false,
  isConnecting: false,
};

const fetchHubstaffProjectsSuccessReducer = (
  state,
  { payload: { projects } }
) => {
  return {
    ...state,
    isFetching: false,
    projects,
  };
};

const fetchConnectHubstaffProjectSuccessReducer = (
  state,
  { payload: { project } }
) => {
  return {
    ...state,
    isConnecting: false,
    connectedProjects: [...state.connectedProjects, project],
  };
};

export const autopilotHubstaffReducer = handleActions(
  {
    [fetchHubstaffProjectsRequest]: mergeLeft({
      isFetching: true,
      projects: [],
    }),
    [fetchHubstaffProjectsSuccess]: fetchHubstaffProjectsSuccessReducer,
    [fetchHubstaffProjectsFailure]: mergeLeft({ isFetching: false }),
    [fetchConnectHubstaffProjectRequest]: mergeLeft({
      isConnecting: true,
    }),
    [fetchConnectHubstaffProjectSuccess]: fetchConnectHubstaffProjectSuccessReducer,
    [fetchConnectHubstaffProjectFailure]: mergeLeft({
      isConnecting: false,
    }),
  },
  defaultState
);
