import { handleActions } from 'redux-actions';
import { mergeDeepLeft, omit } from 'ramda';

import {
  fetchIssuesRequest,
  fetchIssuesSuccess,
  fetchIssuesFailure,
  updateIssue,
  removeIssues,
  removeAssigneeIssues,
  fetchIssuesTimeSuccess,
  fetchAssigneeIssuesSuccess,
} from '../../actions/autopilot';
import { listToItems } from '../../../utils';

const defaultState = {
  items: {},
  assigneeItems: {},
  issuesTime: [],
  totalCount: 0,
  isFetchRequest: false,
};

const fetchGitLabIssuesReducer = (state, { payload }) => ({
  ...state,
  isFetchRequest: false,
  items: listToItems(payload.items, '_id'),
  totalCount: payload.totalCount,
});

const updateIssueReducer = (state, { payload }) => {
  const item = state.items[payload.id];
  const { id, changes } = payload;

  return {
    ...state,
    items: {
      ...state.items,
      [id]: {
        ...item,
        ...changes,
      },
    },
  };
};

const issuesTimeReducer = (state, { payload }) => ({
  ...state,
  issuesTime: payload,
});

const removeIssuesReducer = state => ({
  ...state,
  isFetchRequest: false,
  items: {},
  totalCount: 0,
});

const fetchAssigneeIssuesReducer = (state, { payload }) => ({
  ...state,
  isFetchRequest: false,
  assigneeItems: {
    ...state.assigneeItems,
    [payload.assigneeId]: {
      issuesCount: payload.totalCount,
      items: payload.items,
    },
  },
});

const removeAssigneeIssuesReducer = (state, { payload }) => ({
  ...state,
  isFetchRequest: false,
  assigneeItems: {
    ...omit([payload], state.assigneeItems),
  },
  totalCount: 0,
});

export const autopilotIssuesReducer = handleActions(
  {
    [fetchIssuesRequest]: mergeDeepLeft({ isFetchRequest: true }),
    [fetchIssuesFailure]: mergeDeepLeft({ isFetchRequest: false }),
    [fetchIssuesSuccess]: fetchGitLabIssuesReducer,
    [updateIssue]: updateIssueReducer,
    [fetchIssuesTimeSuccess]: issuesTimeReducer,
    [removeIssues]: removeIssuesReducer,
    [removeAssigneeIssues]: removeAssigneeIssuesReducer,
    [fetchAssigneeIssuesSuccess]: fetchAssigneeIssuesReducer,
  },
  defaultState
);
