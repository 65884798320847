import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { btnSpinner, tangerineGradient, mediaSize } from '../../styles';

const ButtonWithLoader = styled.button`
  width: 100%;
  height: ${props => props.height || '44px'};
  border-radius: ${props => props.borderRadius || '5px'};
  position: relative;
  border: none;
  outline: none;
  ${props => props.maxWidth && `max-width: ${props.maxWidth}px`}
  ${props =>
    props.center &&
    css`
      display: block;
      margin-left: auto;
      margin-right: auto;
    `}
  ${tangerineGradient};
  color: ${props => props.theme.colors.white};
  &:hover {
    cursor: pointer;
  }
  &:before {
    ${props => props.isLoading && btnSpinner}
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.2;
  }
  ${props =>
    props.responsive &&
    css`
      @media (max-width: ${mediaSize.tablet}) {
        height: 38px;
        max-width: 100%;
        width: 100%;
      }
    `}
`;

ButtonWithLoader.propTypes = {
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  maxWidth: PropTypes.number,
  center: PropTypes.bool,
};

export default ButtonWithLoader;
