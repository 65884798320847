import { createAction } from 'redux-actions';

const types = {
  FETCH_PROJECT_ESTIMATIONS: 'FETCH_PROJECT_ESTIMATIONS',
  FETCH_PROJECT_ESTIMATION: 'FETCH_PROJECT_ESTIMATION',
  CREATE_PROJECT_ESTIMATION: 'CREATE_PROJECT_ESTIMATION',
  COPY_PROJECT_ESTIMATION: 'COPY_PROJECT_ESTIMATION',
  MOVE_PROJECT_ESTIMATION: 'MOVE_PROJECT_ESTIMATION',
  UPDATE_ESTIMATION: 'UPDATE_ESTIMATION',
  ESTIMATION_PREVIEW_TOKEN: 'ESTIMATION_PREVIEW_TOKEN',
  ESTIMATION_PREVIEW: 'ESTIMATION_PREVIEW',
  DELETE_ESTIMATION: 'DELETE_ESTIMATION',
  CLEAR_ESTIMATIONS_DATA: 'CLEAR_ESTIMATIONS_DATA',
  CLEAR_ESTIMATION_DATA: 'CLEAR_ESTIMATION_DATA',
};

export const fetchEstimationsRequest = createAction(
  `${types.FETCH_PROJECT_ESTIMATIONS}_REQUEST`
);
export const fetchEstimationsSuccess = createAction(
  `${types.FETCH_PROJECT_ESTIMATIONS}_SUCCESS`
);
export const fetchEstimationsFailure = createAction(
  `${types.FETCH_PROJECT_ESTIMATIONS}_FAILURE`
);

export const fetchEstimationRequest = createAction(
  `${types.FETCH_PROJECT_ESTIMATION}_REQUEST`
);
export const fetchEstimationSuccess = createAction(
  `${types.FETCH_PROJECT_ESTIMATION}_SUCCESS`
);
export const fetchEstimationFailure = createAction(
  `${types.FETCH_PROJECT_ESTIMATION}_FAILURE`
);

export const createEstimationRequest = createAction(
  `${types.CREATE_PROJECT_ESTIMATION}_REQUEST`
);
export const createEstimationFailure = createAction(
  `${types.CREATE_PROJECT_ESTIMATION}_FAILURE`
);

export const copyEstimationRequest = createAction(
  `${types.COPY_PROJECT_ESTIMATION}_REQUEST`
);
export const copyEstimationSuccess = createAction(
  `${types.COPY_PROJECT_ESTIMATION}_SUCCESS`
);
export const copyEstimationFailure = createAction(
  `${types.COPY_PROJECT_ESTIMATION}_FAILURE`
);

export const moveEstimationRequest = createAction(
  `${types.MOVE_PROJECT_ESTIMATION}_REQUEST`
);
export const moveEstimationSuccess = createAction(
  `${types.MOVE_PROJECT_ESTIMATION}_SUCCESS`
);
export const moveEstimationFailure = createAction(
  `${types.MOVE_PROJECT_ESTIMATION}_FAILURE`
);

export const updateEstimationRequest = createAction(
  `${types.UPDATE_ESTIMATION}_REQUEST`
);
export const updateEstimationSuccess = createAction(
  `${types.UPDATE_ESTIMATION}_SUCCESS`
);
export const updateEstimationFailure = createAction(
  `${types.UPDATE_ESTIMATION}_FAILURE`
);
export const getEstimationPreviewTokenRequest = createAction(
  `${types.ESTIMATION_PREVIEW_TOKEN}_REQUEST`
);
export const getEstimationPreviewTokenSuccess = createAction(
  `${types.ESTIMATION_PREVIEW_TOKEN}_SUCCESS`
);
export const fetchEstimationPreviewRequest = createAction(
  `${types.ESTIMATION_PREVIEW}_REQUEST`
);
export const getEstimationPreviewSuccess = createAction(
  `${types.ESTIMATION_PREVIEW}_SUCESS`
);

export const deleteEstimationRequest = createAction(
  `${types.DELETE_ESTIMATION}_REQUEST`
);

export const clearEstimationsData = createAction(
  `${types.CLEAR_ESTIMATIONS_DATA}`
);

export const clearEstimationData = createAction(
  `${types.CLEAR_ESTIMATION_DATA}`
);
