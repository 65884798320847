import { batchActions } from 'redux-batched-actions';
import * as R from 'ramda';
import message from 'antd/lib/message';
import { logout } from '../actions/auth';
import * as ApikoApi from '../../api/apiko-api';

import {
  fetchUsersSuccess,
  fetchUsersRequest,
  fetchUsersFailure,
  archiveUser,
  restoreUser,
  deactivateUser,
  changeUserRole,
  fetchUserByIdRequest,
  clearUsers,
  changeAllowedProjectsRequest,
  changeAllowedProjectsSuccess,
  changeAllowedProjectsFailure,
} from '../actions/users';
import { closeCurrentModal } from '../../modals';

const clearDataAndLogout = dispatch => {
  localStorage.clear();

  dispatch(batchActions([logout, clearUsers()]));
};
/**
 * fetchUsers :: () -> Func -> Promise Error _
 */

export const fetchUsers = () => dispatch =>
  Promise.resolve(dispatch(fetchUsersRequest()))
    .then(() => ApikoApi.fetchUsers())
    .then(result => dispatch(fetchUsersSuccess(result)))
    .catch(() => dispatch(fetchUsersFailure()));

export const fetchUserById = () => dispatch =>
  ApikoApi.fetchUserById(localStorage.getItem('userId'))
    .then(result => {
      dispatch(fetchUserByIdRequest(result.data));
    })
    .catch(() => clearDataAndLogout(dispatch));

export const handleUserArchive = userId => dispatch =>
  ApikoApi.archiveUserById(userId)
    .then(() => dispatch(archiveUser(userId)))
    .then(() => message.success('User successfully archived'))
    .catch(err =>
      message.error(R.path(['response', 'data', 'error', 'message'], err))
    );

export const handleUserRestore = userId => dispatch =>
  ApikoApi.restoreUserById(userId)
    .then(() => dispatch(restoreUser(userId)))
    .catch(err =>
      message.error(R.path(['response', 'data', 'error', 'message'], err))
    );

export const handleChangeUserRole = (_id, data) => dispatch =>
  ApikoApi.changeUserRole(_id, data)
    .then(user => dispatch(changeUserRole(user)))
    .then(() => message.success('User role successfully changed'))
    .catch(err =>
      message.error(R.path(['response', 'data', 'error', 'message'], err))
    );

export const handleUserDeactivated = userId => dispatch =>
  ApikoApi.deactivateUserById(userId)
    .then(() => dispatch(deactivateUser(userId)))
    .then(() => message.success('User successfully removed'))
    .catch(error => {
      if (error.response.status === 404) {
        message.error('User have already accepted invitation');
      } else {
        message.error(error.response.data.error.message);
      }
    });

export const handleAllowedProjectsChange = (
  userId,
  allowedProjects
) => dispatch =>
  Promise.resolve(dispatch(changeAllowedProjectsRequest()))
    .then(() => ApikoApi.changeUserAllowedProjects(userId, allowedProjects))
    .then(user => dispatch(changeAllowedProjectsSuccess(user)))
    .then(() => {
      message.success('Allowed project has been changed');
      dispatch(closeCurrentModal());
    })
    .catch(error => dispatch(changeAllowedProjectsFailure(error)));
