import axios from 'axios';
import * as R from 'ramda';

import { handleApikoHttpResponse } from '../../../redux/utils';

const apiCall = axios.create({
  baseURL: `${process.env.REACT_APP_RECRUITMENT_API_URL}/api`,
});

/**
 * authRequest :: AxiosConfig -> Promise Error AxiosResponse
 * AxiosConfig = Object
 * AxiosResponse = Object
 */
export const authRequest = config => {
  const withApiKeyConfig = R.mergeDeepLeft(config, {
    headers: {
      'api-key': process.env.REACT_APP_RECRUITMENT_API_KEY,
    },
  });

  return apiCall(withApiKeyConfig)
    .then(data => {
      handleApikoHttpResponse();
      return data;
    })
    .catch(error => {
      handleApikoHttpResponse(error);
      return Promise.reject(error);
    });
};
