import React from 'react';
import styled from 'styled-components';
import { Icon as AntIcon } from 'antd';
import { string, func } from 'prop-types';

import { theme as importedTheme } from '../../styles';

export const DropdownContent = styled.div`
  width: ${props => props.width || 170}px;
  border-radius: 2px;
`;

export const DropdownList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const DropdownItem = styled.li`
  min-height: 38px;
  line-height: 1.5;
  display: flex;
  align-items: center;

  a {
    width: 100%;
    text-decoration: none;
    color: ${props => props.theme.colors.fontSilverPrimary};
  }

  button {
    width: 100%;
    text-align: left;
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.table.hoverBg};

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
`;

export const DropdownAction = styled.div`
  width: 100%;
  min-height: 38px;
  display: flex;
  align-items: center;
  padding: ${props => (props.largerPaddings ? '0 15px' : '0 10px')};
  color: ${props => props.color || 'inherit'};

  svg {
    display: inline-block;
    margin-right: 8px;
    width: 17px;
  }

  a {
    display: inline-block;
    width: 100%;
    text-decoration: none;
  }

  span {
    width: 100%;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const DropdownDeleteItem = styled(DropdownItem)`
  color: ${({ theme }) => theme.colors.red};
  border-top: ${({ theme }) => `.9px solid ${theme.colors.borderLightSilver}`};
  margin-bottom: -4px;
  opacity: 0.8;

  .ant-dropdown-trigger {
    display: flex;
    align-items: center;
    width: 100%;
    line-height: 38px;
  }

  span {
    display: flex;
    align-items: center;
    line-height: 16px;
  }

  svg {
    width: 16px;
    margin-left: 3px;
    margin-right: 9px;
  }

  svg path {
    margin-left: 3px;
    fill-opacity: 1;
    fill: ${({ theme }) => theme.colors.red};
  }
  &:hover {
    color: ${({ theme }) => theme.colors.red};
    svg path {
      fill: ${({ theme }) => theme.colors.red};
    }
  }
`;

export const DropdownCopyItem = ({ children, onClick }) => (
  <DropdownItem>
    <DropdownAction {...{ onClick }}>
      <AntIcon
        type="copy"
        theme="filled"
        style={{ color: importedTheme.colors.fontSilver }}
      />
      <span>{children}</span>
    </DropdownAction>
  </DropdownItem>
);

DropdownCopyItem.propTypes = {
  children: string,
  onClick: func,
};

DropdownCopyItem.defaultProps = {
  children: 'Copy',
  onClick: null,
};
