import { isEmpty } from 'ramda';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';

/**
 * Returns human readable duration
 * Default conversion rates are 1mo = 4w, 1w = 5d and 1d = 8h
 * @param {Number} seconds
 * @returns {string}
 */

const getDurationString = (timeUnits, seconds) => {
  let durationString = '';

  timeUnits.forEach(({ label, duration }) => {
    const quotient = Math.floor(seconds / duration);

    if (quotient > 0) {
      seconds -= duration * quotient;
      durationString += ` ${quotient}${label}`;
    }
  });

  return durationString.trim();
};

export const formatDuration = seconds => {
  const minute = 60;
  const hour = 60 * minute;
  const day = 8 * hour;
  const week = 5 * day;
  const month = 4 * week;

  const timeUnits = [
    { label: 'mo', duration: month },
    { label: 'w', duration: week },
    { label: 'd', duration: day },
    { label: 'h', duration: hour },
    { label: 'm', duration: minute },
  ];
  const hoursUnits = [
    { label: 'h', duration: hour },
    { label: 'm', duration: minute },
  ];

  const fullDurationString = getDurationString(timeUnits, seconds);
  const hoursDurationString =
    seconds > day ? ` (${getDurationString(hoursUnits, seconds)})` : '';

  return isEmpty(fullDurationString)
    ? '0'
    : `${fullDurationString}${hoursDurationString}`;
};

export const getDaysArray = (start, end) => {
  try {
    const datesInterval = eachDayOfInterval({ start, end });

    return datesInterval;
  } catch (error) {
    return [];
  }
};
