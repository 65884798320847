import { authRequest } from './utils';

/**
 * fetchProjectRequests :: (String) -> Promise Error Object
 */
export const fetchProjectRequests = projectId =>
  authRequest({
    method: 'GET',
    url: `requests/project-requests/${projectId}`,
  }).then(({ data }) => data);

export const fetchProjectRequestsCount = projectIds =>
  authRequest({
    method: 'GET',
    url: `/requests/projects-requests-count?projects=${projectIds}`,
  }).then(({ data }) => data);
