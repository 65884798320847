import { handleActions } from 'redux-actions';
import { mergeDeepLeft } from 'ramda';
import {
  fetchIntegrationsSuccess,
  fetchIntegrationsRequest,
  fetchIntegrationsFailure,
  fetchUnconnectHubstaffIntegration,
  fetchConnectHubstaffProjectRequest,
  fetchConnectHubstaffProjectSuccess,
  fetchConnectHubstaffProjectFailure,
  fetchHubstaffProjectsRequest,
  fetchHubstaffProjectsSuccess,
  fetchHubstaffProjectsFailure,
  fetchInitializeHubstaffIntegration,
} from '../../actions/autopilot';

const defaultState = {
  isFetching: false,
  helpers: {
    hubstaff: {
      projects: [],
      isFetching: false,
    },
  },
  jira: null,
  gitlab: null,
  testRail: null,
  hubstaff: null,
};
const fetchIntegrationsSuccessReducer = (
  state,
  { payload: { integrations } }
) => ({
  ...state,
  isFetching: false,
  ...integrations,
});
const fetchUnconnectHubstaffIntegrationReducer = (
  state,
  { payload: { hubstaffProjectId } }
) => {
  return {
    ...state,
    hubstaff: state.hubstaff.filter(p => p.id !== hubstaffProjectId),
  };
};
const fetchConnectHubstaffProjectSuccessReducer = (
  state,
  { payload: { project } }
) => {
  return {
    ...state,
    helpers: {
      ...state.helpers,
      hubstaff: {
        ...state.helpers.hubstaff,
        isConnecting: false,
      },
    },
    hubstaff: [...state.hubstaff, project],
  };
};
const fetchHubstaffProjectsSuccessReducer = (
  state,
  { payload: { projects } }
) => {
  return {
    ...state,
    helpers: {
      ...state.helpers,
      hubstaff: {
        ...state.helpers.hubstaff,
        isFetching: false,
        projects,
      },
    },
  };
};
const fetchInitializeHubstaffIntegrationSuccess = (
  state,
  { payload: { hubstaff } }
) => {
  return {
    ...state,
    hubstaff,
  };
};
export const autopilotIntegrationsReducer = handleActions(
  {
    [fetchIntegrationsRequest]: mergeDeepLeft({
      jira: null,
      gitlab: null,
      testRail: null,
      hubstaff: null,
    }),
    [fetchIntegrationsSuccess]: fetchIntegrationsSuccessReducer,
    [fetchIntegrationsFailure]: mergeDeepLeft({
      isFetching: false,
      integrations: {},
    }),
    [fetchUnconnectHubstaffIntegration]: fetchUnconnectHubstaffIntegrationReducer,
    [fetchConnectHubstaffProjectRequest]: mergeDeepLeft({
      isConnecting: true,
    }),
    [fetchConnectHubstaffProjectSuccess]: fetchConnectHubstaffProjectSuccessReducer,
    [fetchConnectHubstaffProjectFailure]: mergeDeepLeft({
      isConnecting: false,
    }),
    [fetchHubstaffProjectsRequest]: mergeDeepLeft({
      helpers: {
        hubstaff: {
          isFetching: true,
          projects: [],
        },
      },
    }),
    [fetchHubstaffProjectsSuccess]: fetchHubstaffProjectsSuccessReducer,
    [fetchHubstaffProjectsFailure]: mergeDeepLeft({
      helpers: {
        hubstaff: {
          isFetching: false,
        },
      },
    }),
    [fetchInitializeHubstaffIntegration]: fetchInitializeHubstaffIntegrationSuccess,
  },
  defaultState
);
