import { createAction } from 'redux-actions';

export const inviteUserRequest = createAction('INVITE_USER_REQUEST');
export const inviteUserSuccess = createAction('INVITE_USER_SUCCESS');
export const inviteUserFailure = createAction('INVITE_USER_FAILURE');
export const inviteUserFetched = createAction('INVITE_USER_FETCHED');

export const acceptInvitationsRequest = createAction(
  'ACCEPT_INVITATIONS_REQUEST'
);
export const acceptInvitationsEnd = createAction('ACCEPT_INVITATIONS_END');
