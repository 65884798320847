import { createAction } from 'redux-actions';

const types = {
  FETCH_ESTIMATION_TASKS: 'FETCH_ESTIMATION_TASKS',
  CREATE_ESTIMATION_TASK: 'CREATE_ESTIMATION_TASK',
  UPDATE_ESTIMATION_TASK: 'UPDATE_ESTIMATION_TASK',
  DELETE_ESTIMATION_TASK: 'DELETE_ESTIMATION_TASK',
  DELETE_ESTIMATIONS_BY_SECTION: 'DELETE_ESTIMATIONS_BY_SECTION',
  CREATE_ESTIMATION_TASK_COMMENT: 'CREATE_ESTIMATION_TASK_COMMENT',
  FETCH_ESTIMATION_TASK_COMMENTS: 'FETCH_ESTIMATION_TASK_COMMENTS',
  REMOVE_TASKS: 'REMOVE_TASKS',
  SET_VIEWED_ESTIMATION_COMMENTS: 'SET_VIEWED_ESTIMATION_COMMENTS',
  UPDATE_ESTIMATION_TASK_COMMENT: 'UPDATE_ESTIMATION_TASK_COMMENT',
  UPDATE_ESTIMATIONS_COST: 'UPDATE_ESTIMATIONS_COST',
};

export const fetchEstimationTasksRequest = createAction(
  `${types.FETCH_ESTIMATION_TASKS}_REQUEST`
);
export const fetchEstimationTasksSuccess = createAction(
  `${types.FETCH_ESTIMATION_TASKS}_SUCCESS`
);
export const fetchEstimationTasksFailure = createAction(
  `${types.FETCH_ESTIMATION_TASKS}_FAILURE`
);

export const createEstimationTaskRequest = createAction(
  `${types.CREATE_ESTIMATION_TASK}_REQUEST`
);
export const createEstimationTaskSuccess = createAction(
  `${types.CREATE_ESTIMATION_TASK}_SUCCESS`
);
export const createEstimationTaskFailure = createAction(
  `${types.CREATE_ESTIMATION_TASK}_FAILURE`
);

export const updateEstimationTaskRequest = createAction(
  `${types.UPDATE_ESTIMATION_TASK}_REQUEST`
);
export const updateEstimationTaskSuccess = createAction(
  `${types.UPDATE_ESTIMATION_TASK}_SUCCESS`
);
export const updateEstimationTaskFailure = createAction(
  `${types.UPDATE_ESTIMATION_TASK}_FAILURE`
);

export const deleteEstimationTaskRequest = createAction(
  `${types.DELETE_ESTIMATION_TASK}_REQUEST`
);
export const deleteEstimationTaskSuccess = createAction(
  `${types.DELETE_ESTIMATION_TASK}_SUCCESS`
);
export const deleteEstimationTaskFailure = createAction(
  `${types.DELETE_ESTIMATION_TASK}_FAILURE`
);

export const deleteEstimationTasksBySection = createAction(
  types.DELETE_ESTIMATIONS_BY_SECTION
);
export const removeEstimationTasksFromReducer = createAction(
  types.REMOVE_TASKS
);
export const createEstimationTaskCommentRequest = createAction(
  `${types.CREATE_ESTIMATION_TASK_COMMENT}_REQUEST`
);

export const createEstimationTaskCommentSuccess = createAction(
  `${types.CREATE_ESTIMATION_TASK_COMMENT}_SUCCESS`
);

export const createEstimationTaskCommentFailure = createAction(
  `${types.CREATE_ESTIMATION_TASK_COMMENT}_FAILURE`
);

export const updateEstimationTaskCommentRequest = createAction(
  `${types.UPDATE_ESTIMATION_TASK_COMMENT}_REQUEST`
);
export const updateEstimationTaskCommentSuccess = createAction(
  `${types.UPDATE_ESTIMATION_TASK_COMMENT}_SUCCESS`
);
export const updateEstimationTaskCommentFailure = createAction(
  `${types.UPDATE_ESTIMATION_TASK_COMMENT}_FAILURE`
);

// Estimation's Comments
export const fetchEstimationTaskCommentsRequest = createAction(
  `${types.FETCH_ESTIMATION_COMMENTS}_REQUEST`
);
export const fetchEstimationTaskCommentsSuccess = createAction(
  `${types.FETCH_ESTIMATION_COMMENTS}_SUCCESS`
);
export const fetchEstimationTaskCommentsFailure = createAction(
  `${types.FETCH_ESTIMATION_COMMENTS}_FAILURE`
);

export const setViewedEstimationCommentsSuccess = createAction(
  `${types.SET_VIEWED_ESTIMATION_COMMENTS}_SUCCESS`
);
export const setViewedEstimationCommentsFailure = createAction(
  `${types.SET_VIEWED_ESTIMATION_COMMENTS}_FAILURE`
);

export const updateEstimationsCostRequest = createAction(
  `${types.UPDATE_ESTIMATIONS_COST}_REQUEST`
);
