import React from 'react';
import { Tooltip } from 'antd';
import { number, string } from 'prop-types';

import { TimeTrackingWrapper } from './styled-components';
import { Progress } from './progress';
import { Compare } from './compare';
import { formatDuration } from '../../utils';

export const TimeTrackingProgress = ({
  humanTotalTimeSpent,
  timeSpendPercentage,
  humanTimeEstimate,
  progressBarHeight,
  totalTimeSpent,
  timeEstimate,
  maxWidth,
}) => {
  const remaining =
    totalTimeSpent < timeEstimate ? timeEstimate - totalTimeSpent : 0;

  return (
    <Tooltip title={`Time remaining: ${formatDuration(remaining)}`}>
      <TimeTrackingWrapper maxWidth={maxWidth}>
        <Compare
          humanTimeEstimate={humanTimeEstimate}
          humanTotalTimeSpent={humanTotalTimeSpent}
        />
        <Progress
          timeSpendPercentage={timeSpendPercentage}
          totalTimeSpent={totalTimeSpent}
          timeEstimate={timeEstimate}
          height={progressBarHeight}
        />
      </TimeTrackingWrapper>
    </Tooltip>
  );
};

TimeTrackingProgress.propTypes = {
  // humanTimeEstimate and humanTotalTimeSpent will be a human readable strings like "1h 59m"
  humanTimeEstimate: string,
  humanTotalTimeSpent: string,
  timeEstimate: number,
  totalTimeSpent: number,
  maxWidth: number,
  progressBarHeight: number,
  timeSpendPercentage: number.isRequired,
};

TimeTrackingProgress.defaultProps = {
  maxWidth: null,
  humanTimeEstimate: null,
  humanTotalTimeSpent: null,
  timeEstimate: 0,
  totalTimeSpent: 0,
  progressBarHeight: 10,
};
