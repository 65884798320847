import { sort, path } from 'ramda';

/**
 * @typedef {Object} TimeStats
 * @property {Number} timeSpent
 * @property {Number} timeEstimate
 */

/**
 * @typedef {Object} Issue
 * @property {TimeStats} timeTracking
 */

/**
 * Returns time spend ratio of an issue
 * @param {Issue} issue
 * @returns {Number}
 */
export const getTimeSpendPercentage = path([
  'timeTracking',
  'timeSpendPercentage',
]);

/**
 * Sort issues by time spend ratio, such that
 * issues with have less remaining time come first
 * @param {Array.<Issue>} issues - array of issues
 * @returns {Array.<Issue>} - array of issues sorted by time spend
 */
export const sortBySpentTime = sort(
  (a, b) => getTimeSpendPercentage(b) - getTimeSpendPercentage(a)
);
