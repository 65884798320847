import * as R from 'ramda';
import { combineActions, handleActions } from 'redux-actions';
import { listToItems, getChildIdsByParentId } from '../../utils/general';
import {
  fetchEstimationTasksRequest,
  fetchEstimationTasksSuccess,
  fetchEstimationTasksFailure,
  deleteEstimationTasksBySection,
  createEstimationTaskRequest,
  createEstimationTaskSuccess,
  createEstimationTaskFailure,
  // updateEstimationTaskRequest,
  updateEstimationTaskSuccess,
  updateEstimationTaskFailure,
  // deleteEstimationTaskRequest,
  deleteEstimationTaskSuccess,
  deleteEstimationTaskFailure,
  createEstimationTaskCommentRequest,
  createEstimationTaskCommentSuccess,
  fetchEstimationTaskCommentsSuccess,
  removeEstimationTasksFromReducer,
  setViewedEstimationCommentsSuccess,
  setViewedEstimationCommentsFailure,
  updateEstimationTaskCommentRequest,
  updateEstimationTaskCommentSuccess,
} from '../actions/estimationTasks';

const defaultState = {
  error: null,
  isFetching: false,
  isCreateRequest: false,
  items: [],
};

const failureReducer = (state, { payload }) => ({
  ...state,
  error: payload,
  isFetching: false,
  isCreateRequest: false,
});

const fetchEstimationTasksSuccessReducer = (state, { payload }) => ({
  ...state,
  error: null,
  isFetching: false,
  items: {
    ...state.items,
    ...listToItems(payload),
  },
});

const createEstimationSuccessReducer = (state, { payload: estimationTask }) => {
  // if we create subitem we need to reset parent estimationTask data
  if (estimationTask.parentId) {
    const parentEstimation = R.pathOr(
      {},
      ['items', estimationTask.parentId],
      state
    );
    return {
      ...state,
      isCreateRequest: false,
      items: {
        ...state.items,
        [estimationTask._id]: estimationTask,
        [parentEstimation._id]: {
          ...parentEstimation,
          likely: 0,
          average: 0,
          optimistic: 0,
          pessimistic: 0,
          design: 0,
          frontend: 0,
          backend: 0,
        },
      },
    };
  }

  return {
    ...state,
    isCreateRequest: false,
    items: {
      ...state.items,
      [estimationTask._id]: estimationTask,
    },
  };
};

const updateEstimationTaskSuccessReducer = (
  state,
  { payload: estimationTask }
) => {
  // this is usage in case we create item with subitems from library
  // in response will be children field with all subitems
  if (estimationTask.children) {
    return {
      ...state,
      error: null,
      items: {
        ...state.items,
        ...listToItems(estimationTask.children),
        [estimationTask._id]: R.omit(['children'], estimationTask),
      },
    };
  }

  return {
    ...state,
    error: null,
    items: {
      ...state.items,
      [estimationTask._id]: estimationTask,
    },
  };
};

const deleteEstimationTasksBySectionReducer = (
  { items, ...state },
  { payload }
) => ({
  ...state,
  items: R.omit(getChildIdsByParentId(payload._id, 'sectionId', items), items),
});

const deleteEstimationTaskSuccessReducer = (
  state,
  { payload: tasksToDelete }
) => ({
  ...state,
  error: null,
  items: R.omit(tasksToDelete, state.items),
});
const removeEstimationTasksReducer = state => ({
  ...state,
  error: null,
  isFetching: false,
  isCreateRequest: false,
  items: {},
});
/* Success Fetch / Create / Update Comment(-s) */
const estimationCommentSuccessReducer = (
  state,
  { payload: { comments, estimationId } }
) => {
  const updatedComments = R.compose(
    commentsList => ({
      ...(R.is(Array, commentsList) ? listToItems(commentsList) : commentsList),
      ...listToItems(comments),
    }),
    R.pathOr({}, ['items', estimationId, 'comments'])
  )(state);

  return {
    ...state,
    isFetchingComments: false,
    isCommentCreating: false,
    isCommentUpdating: false,
    items: {
      ...state.items,
      [estimationId]: {
        ...state.items[estimationId],
        comments: updatedComments,
      },
    },
  };
};
const setViewedEstimationCommentsSuccessReducer = (
  state,
  { payload: { userId, estimationTaskId, comments } }
) => {
  const updatedComments = comments.reduce((acc, commentId) => {
    const comment = R.path(
      ['items', estimationTaskId, 'comments', commentId],
      state
    );

    if (!comment.viewedBy.includes(userId)) {
      return {
        ...acc,
        [commentId]: {
          ...comment,
          viewedBy: comment.viewedBy.concat([userId]),
        },
      };
    }

    return acc;
  }, {});

  const updatedItem = {
    [estimationTaskId]: {
      ...state.items[estimationTaskId],
      comments: {
        ...R.pathOr([], ['items', estimationTaskId, 'comments'], state),
        ...updatedComments,
      },
    },
  };

  return {
    ...state,
    items: {
      ...state.items,
      ...updatedItem,
    },
  };
};
const setViewedEstimationCommentsFailureReducer = (
  state,
  { payload: { userId, estimationId, comments } }
) => {
  const updatedComments = comments.reduce((acc, commentId) => {
    const comment = R.path(
      ['items', estimationId, 'comments', commentId],
      state
    );
    const viewedBy = R.pathOr([], ['viewedBy'], comment);

    if (viewedBy.includes(userId)) {
      return {
        ...acc,
        [commentId]: {
          ...comment,
          viewedBy: R.reject(R.equals(userId), viewedBy),
        },
      };
    }

    return acc;
  }, {});

  const updatedItem = {
    [estimationId]: {
      ...state.items[estimationId],
      comments: {
        ...R.pathOr([], ['items', estimationId, 'comments'], state),
        ...updatedComments,
      },
    },
  };

  return {
    ...state,
    items: {
      ...state.items,
      ...updatedItem,
    },
  };
};
export const estimationTasksReducer = handleActions(
  {
    [fetchEstimationTasksRequest]: R.mergeDeepLeft({ isFetching: true }),
    [combineActions(
      fetchEstimationTasksFailure,
      createEstimationTaskFailure,
      updateEstimationTaskFailure,
      deleteEstimationTaskFailure
    )]: failureReducer,
    [fetchEstimationTasksSuccess]: fetchEstimationTasksSuccessReducer,
    [deleteEstimationTasksBySection]: deleteEstimationTasksBySectionReducer,
    [createEstimationTaskRequest]: R.mergeDeepLeft({ isCreateRequest: true }),
    [createEstimationTaskSuccess]: createEstimationSuccessReducer,
    [updateEstimationTaskSuccess]: updateEstimationTaskSuccessReducer,
    [createEstimationTaskCommentRequest]: R.mergeDeepLeft({
      isCommentCreating: true,
    }),
    [updateEstimationTaskCommentRequest]: R.mergeDeepLeft({
      isCommentUpdating: true,
    }),
    [combineActions(
      fetchEstimationTaskCommentsSuccess,
      createEstimationTaskCommentSuccess,
      updateEstimationTaskCommentSuccess
    )]: estimationCommentSuccessReducer,
    [deleteEstimationTaskSuccess]: deleteEstimationTaskSuccessReducer,
    [removeEstimationTasksFromReducer]: removeEstimationTasksReducer,
    [setViewedEstimationCommentsSuccess]: setViewedEstimationCommentsSuccessReducer,
    [setViewedEstimationCommentsFailure]: setViewedEstimationCommentsFailureReducer,
  },
  defaultState
);
