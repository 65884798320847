import { createAction } from 'redux-actions';

const types = {
  FETCH_ORGANIZATION: 'FETCH_ORGANIZATION',
  UPDATE_ORGANIZATION: 'UPDATE_ORGANIZATION',
};

export const fetchOrganizationRequest = createAction(
  `${types.FETCH_ORGANIZATION}_REQUEST`
);
export const fetchOrganizationSuccess = createAction(
  `${types.FETCH_ORGANIZATION}_SUCCESS`
);
export const fetchOrganizationFailure = createAction(
  `${types.FETCH_ORGANIZATION}_FAILURE`
);

export const updateOrganizationRequest = createAction(
  `${types.UPDATE_ORGANIZATION}_REQUEST`
);
export const updateOrganizationSuccess = createAction(
  `${types.UPDATE_ORGANIZATION}_SUCCESS`
);
export const updateOrganizationFailure = createAction(
  `${types.UPDATE_ORGANIZATION}_FAILURE`
);
