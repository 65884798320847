import * as R from 'ramda';
import { combineActions, handleActions } from 'redux-actions';

import {
  fetchFeedbackRequest,
  fetchFeedbackSuccess,
  fetchFeedbackFailure,
  postFeedbackRequest,
  postFeedbackSuccess,
  postFeedbackFailure,
  fetchFeedbackIssuesRequest,
  fetchFeedbackIssuesSuccess,
  fetchFeedbackIssuesFailure,
  fetchProjectFeedbacksRequest,
  fetchProjectFeedbacksSuccess,
  fetchProjectFeedbacksFailure,
  clearFeedbacks,
} from '../actions/feedbacks';

const initialState = {
  items: [],
  currentItem: {},
  closedIssues: [],
  closedIssuesCount: 0,
  isFetching: false,
  isUpdating: false,
  isFetchingIssues: false,
  error: null,
};

const failureReducer = (state, { payload }) => ({
  ...state,
  isFetching: false,
  isUpdating: false,
  isFetchingIssues: false,
  error: payload,
});

const fetchFeedbackSuccessReducer = (state, { payload }) => ({
  ...state,
  isFetching: false,
  isUpdating: false,
  isFetchingIssues: false,
  error: null,
  currentItem: payload.feedback,
  closedIssues: payload.issues.items,
  closedIssuesCount: payload.issues.count,
});

const postFeedbackSuccessReducer = (state, { payload }) => ({
  ...state,
  isFetching: false,
  isUpdating: false,
  error: null,
  currentItem: {
    ...state.currentItem,
    ...payload.feedback,
  },
});

const fetchFeedbackIssuesSuccessReducer = (state, { payload }) => ({
  ...state,
  isFetching: false,
  isUpdating: false,
  isFetchingIssues: false,
  error: null,
  closedIssues: state.closedIssues.concat(payload.issues.items),
  closedIssuesCount: payload.issues.count,
});

const fetchProjectFeedbacksSuccessReducer = (state, { payload }) => ({
  ...state,
  isFetching: false,
  isUpdating: false,
  isFetchingIssues: false,
  error: null,
  items: payload,
});

const clearFeedbacksReducer = () => initialState;

export const feedbacksReducers = handleActions(
  {
    [combineActions(
      fetchFeedbackRequest,
      fetchProjectFeedbacksRequest
    )]: R.mergeDeepLeft({ isFetching: true }),
    [postFeedbackRequest]: R.mergeDeepLeft({ isUpdating: true }),
    [fetchFeedbackIssuesRequest]: R.mergeDeepLeft({ isFetchingIssues: true }),
    [combineActions(
      postFeedbackFailure,
      fetchFeedbackFailure,
      fetchFeedbackIssuesFailure,
      fetchProjectFeedbacksFailure
    )]: failureReducer,
    [clearFeedbacks]: clearFeedbacksReducer,
    [fetchFeedbackSuccess]: fetchFeedbackSuccessReducer,
    [postFeedbackSuccess]: postFeedbackSuccessReducer,
    [fetchFeedbackIssuesSuccess]: fetchFeedbackIssuesSuccessReducer,
    [fetchProjectFeedbacksSuccess]: fetchProjectFeedbacksSuccessReducer,
  },
  initialState
);
