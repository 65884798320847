import { createAction } from 'redux-actions';

const types = {
  CREATE_ATTACHMENTS: 'CREATE_ATTACHMENTS',
  FETCH_ATTACHMENTS: 'FETCH_ATTACHMENTS',
  REMOVE_ATTACHMENT: 'REMOVE_ATTACHMENT',
  DELETE_ATTACHMENTS_BY_PROJECT: 'DELETE_ATTACHMENTS_BY_PROJECT',
};

export const createAttachmentsRequest = createAction(
  `${types.CREATE_ATTACHMENTS}_REQUEST`
);
export const createAttachmentsSuccess = createAction(
  `${types.CREATE_ATTACHMENTS}_SUCCESS`
);
export const createAttachmentsFailure = createAction(
  `${types.CREATE_ATTACHMENTS}_FAILURE`
);

export const fetchAttachmentsRequest = createAction(
  `${types.FETCH_ATTACHMENTS}_REQUEST`
);
export const fetchAttachmentsSuccess = createAction(
  `${types.FETCH_ATTACHMENTS}_SUCCESS`
);
export const fetchAttachmentsFailure = createAction(
  `${types.FETCH_ATTACHMENTS}_FAILURE`
);

export const removeAttachmentRequest = createAction(
  `${types.REMOVE_ATTACHMENT}_REQUEST`
);
export const removeAttachmentSuccess = createAction(
  `${types.REMOVE_ATTACHMENT}_SUCCESS`
);
export const removeAttachmentFailure = createAction(
  `${types.REMOVE_ATTACHMENT}_FAILURE`
);
export const deleteAttachmentsByProject = createAction(
  types.DELETE_ATTACHMENTS_BY_PROJECT
);
