import message from 'antd/lib/message';
import R from 'ramda';

import * as ApikoApi from '../../api/apiko-api';
import {
  fetchOrganizationRequest,
  fetchOrganizationSuccess,
  fetchOrganizationFailure,
  updateOrganizationSuccess,
  updateOrganizationFailure,
} from '../actions/organization';

/**
 * handleOrganizationFetch :: _ -> Func -> Promise Error [Object]
 */
export const handleOrganizationFetch = () => dispatch =>
  Promise.resolve(dispatch(fetchOrganizationRequest()))
    .then(() => ApikoApi.fetchOrganizationSettings())
    .then(response => dispatch(fetchOrganizationSuccess(response)))
    .catch(error => dispatch(fetchOrganizationFailure(error)));

/**
 * handleOrganizationSettingsUpdate :: (ID, Object) -> Func -> Promise Error _
 */
export const handleOrganizationSettingsUpdate = (key, modifier) => (
  dispatch,
  getState
) => {
  const currentSettings = R.path(['organization', 'settings'], getState());

  return Promise.resolve(
    dispatch(
      updateOrganizationSuccess({
        settings: { ...currentSettings, [key]: modifier },
      })
    )
  )
    .then(() => ApikoApi.updateOrganizationSettings({ [key]: modifier }))
    .catch(error => {
      message.error(`Fail to update organization setting: ${error.message}`);
      dispatch(updateOrganizationFailure(error));
    });
};

export const handleCreateAdditionalCost = data => dispatch => {
  return Promise.resolve(ApikoApi.createAdditionalCostForOrganization(data))
    .then(({ data: { organizationSetting } }) => {
      const settings = {
        ...organizationSetting,
        additionalCosts: organizationSetting.additionalCosts,
      };

      dispatch(updateOrganizationSuccess({ settings }));
    })
    .catch(error => {
      message.error(`Fail to update organization setting: ${error.message}`);
      dispatch(updateOrganizationFailure(error));
    });
};
