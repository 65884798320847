import { createAction } from 'redux-actions';

export const types = {
  SIGN_IN: 'SIGN_IN',
  RESTORE_PASSWORD: 'RESTORE_PASSWORD',
  SAVE_ORIGIN_URL: 'SAVE_ORIGIN_URL',
};

export const saveOriginUrl = createAction(types.SAVE_ORIGIN_URL);

export const signInRequest = createAction(`${types.SIGN_IN}_REQUEST`);
export const signInSuccess = createAction(`${types.SIGN_IN}_SUCCESS`);
export const signInFailure = createAction(`${types.SIGN_IN}_FAILURE`);

export const restorePasswordRequest = createAction(
  `${types.RESTORE_PASSWORD}_REQUEST`
);
export const restorePasswordSuccess = createAction(
  `${types.RESTORE_PASSWORD}_SUCCESS`
);
export const restorePasswordFailure = createAction(
  `${types.RESTORE_PASSWORD}_FAILURE`
);

export const logout = createAction('LOGOUT');
