import { authRequest, apikoApi } from './utils';

/**
 * createAttachment :: (String, FormData) -> Promise Error Object
 */
export const createAttachment = formData =>
  authRequest({
    method: 'POST',
    data: formData,
    url: '/attachments',
  }).then(({ data }) => data);

/**
 * fetchAttachmentsForProject :: ID -> Promise Error Object
 */
export const fetchAttachmentsForProject = (rootId, type) =>
  authRequest({
    method: 'GET',
    url: `/attachments/${rootId}/${type}`,
  }).then(({ data }) => data);

/**
 * fetchProjectAttachmentsPreview :: String -> Promise Error Object
 */
export const fetchProjectAttachmentsPreview = (previewToken, rootId, type) =>
  apikoApi({
    method: 'GET',
    url: `/attachments/preview/${rootId}/${type}?previewToken=${previewToken}`,
  }).then(({ data }) => data);

/**
 * removeAttachmentFromProject :: (ID, ID) -> Promise Error Object
 */
export const removeAttachmentFromProject = ({ attachmentId, rootId, type }) =>
  authRequest({
    method: 'DELETE',
    url: '/attachments',
    data: {
      type,
      rootId,
      attachmentId,
    },
  }).then(({ data }) => data);
