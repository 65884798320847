import * as R from 'ramda';

/**
 * isProd :: () -> Boolean
 */
export const isProd = () => process.env.NODE_ENV === 'production';

/**
 * isUnauthorizedError :: Error -> Boolean
 */
export const isUnauthorizedError = R.pathEq(['response', 'status'], 401);
