import axios from 'axios';
import * as R from 'ramda';

import { handleApikoHttpResponse } from '../../redux/utils';

export const apiServer =
  process.env.REACT_APP_API_URL || 'http://localhost:3000';

export const apiBaseUrl = `${apiServer}/api`;

export const apikoApi = axios.create({
  baseURL: apiBaseUrl,
});

export const openPDFWindow = (estimationData, costToken) => {
  const query = costToken ? `?costToken=${estimationData.costToken}` : '';
  window.open(`${apiBaseUrl}/estimations/export/${estimationData._id}${query}`);
};

/**
 * authRequest :: AxiosConfig -> Promise Error AxiosResponse
 * AxiosConfig = Object
 * AxiosResponse = Object
 */
export const authRequest = config => {
  const token = localStorage.getItem('token');

  const withAuthTokenConfig = R.mergeDeepLeft(config, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return apikoApi(withAuthTokenConfig)
    .then(data => {
      handleApikoHttpResponse();
      return data;
    })
    .catch(error => {
      handleApikoHttpResponse(error);
      return Promise.reject(error);
    });
};
