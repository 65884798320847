import { curry, pathOr, isEmpty, isNil } from 'ramda';

import { sortByRank } from './general';
import { defaultRoundingOptions, round } from './rounding';

/**
 * estimationAverage :: EstimationItem -> Number
 */
export const estimationAverage = (
  { optimistic = 0, likely = 0, pessimistic = 0, subItems = [] },
  roundingOptions
) => {
  // Earlier we rounded average up using Math.ceil, but later we decided to
  // round average to the second decimal place. In order to not change the total
  // of projects estimate before such decision we add two fields
  // to the project schema (roundingMode and roundToPrecision).
  // Based on these fields we will know which rounding method to use
  // for a particular project
  if (!isEmpty(subItems)) {
    const subItemsAverage = subItems.reduce(
      (acc, item) =>
        acc + (item.optimistic + 4 * item.likely + item.pessimistic) / 6,
      0
    );

    return round(subItemsAverage, roundingOptions);
  }

  if (optimistic > 0 && likely > 0 && pessimistic > 0) {
    return round((optimistic + 4 * likely + pessimistic) / 6, roundingOptions);
  }

  return 0;
};

export const estimationTotal = (
  { design = 0, frontend = 0, backend = 0 },
  roundingOptions
) =>
  round(Number(design) + Number(frontend) + Number(backend), roundingOptions);

export const estimationItemWithAverage = (estimation, roundingOptions) => ({
  ...estimation,
  average:
    estimation.type === 'average'
      ? estimationAverage(estimation, roundingOptions)
      : estimationTotal(estimation, roundingOptions),
});

export const estimationsListWithAverage = curry(
  (roundingOptions, estimations) =>
    estimations.map(estimation =>
      estimationItemWithAverage(estimation, roundingOptions)
    )
);

export const getAdditionalCostByKey = (key, additionalCosts) =>
  additionalCosts.find(cost => cost.key === key);

export const updateAdditionalCostItem = (newValue, item, key) => ({
  ...item,
  [key]: newValue,
});

export const getUpdatedAdditionalCosts = (
  value,
  key,
  fillName,
  additionalCosts
) => {
  const oldItem = getAdditionalCostByKey(key, additionalCosts);
  const newItem = updateAdditionalCostItem(value, oldItem, fillName);
  if (newItem[fillName] !== oldItem[fillName] && value !== '') {
    const updatedAdditionalCosts = additionalCosts.map(item => {
      if (item.key === key) {
        return newItem;
      }

      return item;
    });

    return updatedAdditionalCosts;
  }

  return [];
};

export const getEstimationsForPreview = (
  projects,
  projectId,
  sectionId = null
) => {
  const { items } = projects;
  const { sectionItems, roundingMode, roundToPrecision } =
    items.find(({ _id }) => _id === projectId) || {};
  let sectionItemsArr = sectionItems || [];
  if (sectionId) {
    sectionItemsArr = sectionItems.filter(item => item.sectionId === sectionId);
  }
  return sectionItemsArr.map(item => ({
    ...item,
    average: estimationAverage(item, { roundingMode, roundToPrecision }),
  }));
};

export const generateMilestoneDataByOrder = order => ({
  name: `M${order}`,
  order,
});

/**
 * Returns a new sub item estimation based on it's parent
 * @param {Object} parentEstimation - parent estimation document
 * @returns {Object} - new sub item estimation
 */
export const generateSubItem = (parentEstimation, priority, milestone = []) => {
  const { _id, estimationId, sectionId, rank } = parentEstimation;

  const subItem = {
    estimationId,
    sectionId,
    parentId: _id,
    name: '',
    rank: rank || 0,
  };

  if (!isEmpty(milestone)) {
    subItem.milestone = generateMilestoneDataByOrder(
      milestone[milestone.length - 1]
    );
  }

  if (priority) {
    subItem.priority = priority;
  }

  return subItem;
};

/**
 * Returns a list of sub items for a parent
 * @param {Object} parentEstimation - parent estimation document
 * @param {Array} estimations - a list of all estimations
 * @returns {Array}
 */
export const getEstimationSubItems = (parentEstimation, estimations) =>
  estimations.filter(({ parentId }) => parentId === parentEstimation._id);

/**
 * Returns true whether the estimation has sub items
 * @param {Object} parentEstimation - parent estimation document
 * @param {Array} estimations - a list of all estimations
 * @returns {Boolean}
 */
export const estimationHasSubItems = (parentEstimation, estimations) =>
  estimations.findIndex(({ parentId }) => parentId === parentEstimation._id) >
  -1;

const calculateTimesSum = (
  memo,
  { likely, design, average, backend, frontend, optimistic, pessimistic }
) => ({
  average: memo.average + average,
  likely: memo.likely + likely,
  design: memo.design + design,
  backend: memo.backend + backend,
  frontend: memo.frontend + frontend,
  optimistic: memo.optimistic + optimistic,
  pessimistic: memo.pessimistic + pessimistic,
});

/**
 * Returns updated parent estimation document with correct times (based on sub items' times)
 * @param {Object} parentEstimation - parent estimation document
 * @returns {Object} - estimation with correct times
 */
const calculateTimesForParent = parentEstimation => {
  const { subItems } = parentEstimation;

  if (subItems && subItems.length > 0) {
    const timesSum = parentEstimation.subItems.reduce(calculateTimesSum, {
      average: 0,
      likely: 0,
      design: 0,
      backend: 0,
      frontend: 0,
      optimistic: 0,
      pessimistic: 0,
    });

    return {
      ...parentEstimation,
      ...timesSum,
    };
  }

  return parentEstimation;
};

/**
 * Returns a list of top-level estimations with sub items inside
 * @param {Array} estimations - a list of all estimations
 * @returns {Array} - top-level estimations
 */
export const getTasksWithSubTasks = estimations => {
  const parents = estimations.filter(({ parentId }) => !parentId);
  const subItems = estimations.filter(({ parentId }) => !!parentId);

  return parents
    .map(parentEstimation => ({
      ...parentEstimation,
      subItems: calculateTimesForParent(
        getEstimationSubItems(parentEstimation, subItems).sort(sortByRank)
      ),
    }))
    .sort(sortByRank);
};

/**
 * Returns a rounded total cost of estimations
 * @param {Array} estimations - a list of estimations
 * @param {Object} roundingOptions
 * @param {ROUNDING_MODES} roundingOptions.roundingMode
 * @param {Number} roundingOptions.roundToPrecision - the number of digits
 *   to appear after the decimal point
 * @returns {Number} - total cost of estimations
 */
export const getEstimationsTotalCost = (estimations, roundingOptions) => {
  // We display the rounded cost for each item on UI.
  // When we calculate a total costs we need to sum rounded costs:
  // round(i.average * i.cost, roundingOptions) - sum values which displayed on UI
  // Because if we just round a sum in the end (without rounding cost of each item)
  // we will get a result which is different from what user might expect.
  //
  // For example:
  // cost = 0.1$;
  // Name    Optimistic  Likely  Pessimistic   Average  Costs
  // parent      2         4          8         0.43      0.43 <- SHOULD BE 0.44
  //  child 1    1         2          4         2.17      0.22 <- round(0.217)
  //  child 2    1         2          4         2.17      0.22 <- round(0.217)
  //
  // parent cost = round(0.217 + 0.217) = round(0.434) = 0.43
  //
  // So we need to sum rounded numbers, as displayed in UI
  // parent cost = round(0.217) + round(0.217) = 0.22 + 0.22 = 0.44
  const totalCost = estimations.reduce(
    (total, { average, cost, subItems = [] }) => {
      let subItemsTotal = 0;
      if (!isNil(subItems) && !isEmpty(subItems)) {
        subItemsTotal = subItems.reduce(
          (acc, value) => acc + value.average * value.cost,
          0
        );
      }

      return total + round(average * cost + subItemsTotal, roundingOptions);
    },
    0
  );

  // We round once more because addition may also produce numbers which should be rounded
  // For example: 0.1 + 0.2 = 0.30000000000000004
  return round(totalCost, roundingOptions);
};

/**
 * Returns a total cost of estimations
 * @param {String} parentId - id of parent estimation
 * @param {Array} estimations - a list of estimations
 * @param {Object} roundingOptions
 * @param {ROUNDING_MODES} roundingOptions.roundingMode
 * @param {Number} roundingOptions.roundToPrecision - the number of digits
 *   to appear after the decimal point
 * @returns {Number} - total cost of estimations
 */

export const isValidCost = value =>
  /^\d*$|^\d+\.\d{0,2}$/.test(value) && value <= 10000;

export const calculateItemRank = ({
  above,
  rank,
  previousEstimationRank = null,
  nextEstimationRank = null,
}) => {
  const handleRank = (currentRank, nearItemRank) => {
    let calculatedNearRank = nearItemRank;
    if (nearItemRank === null) {
      calculatedNearRank = currentRank;
    }
    return (rank + calculatedNearRank) / 2;
  };
  const calculatedRank = above
    ? handleRank(rank - 1, previousEstimationRank)
    : handleRank(rank + 1, nextEstimationRank);

  return calculatedRank;
};

export const getNearItemRank = ({ estimations, index, isNext }) => {
  const calculatedIndex = isNext ? index + 1 : index - 1;
  // pathOr returns last item instead of false, if we pass -1, this condition for preventing such type of behaviour
  if (calculatedIndex < 0) {
    return null;
  }
  return pathOr(null, [calculatedIndex, 'rank'], estimations);
};

export const nullifyEstimation = estimation => ({
  ...estimation,
  optimistic: 0,
  likely: 0,
  pessimistic: 0,
  design: 0,
  frontend: 0,
  backend: 0,
  average: 0,
});

const calculateEstimationsTotalCosts = item => {
  let currentSum = 0;
  let totalCost = item.average * item.cost;

  if (totalCost === 0 && item.average === 0 && !isEmpty(item.subItems)) {
    currentSum = item.subItems.reduce(
      (acc, value) => acc + value.cost * value.average,
      0
    );
  }
  totalCost += currentSum;
  return {
    ...item,
    totalCost,
  };
};

export const formatEstimationTasks = (
  estimations = [],
  roundingOptions = defaultRoundingOptions
) => {
  const parents = estimations.filter(({ parentId }) => !parentId);
  const subItems = estimationsListWithAverage(
    roundingOptions,
    estimations.filter(({ parentId }) => !!parentId)
  );

  return parents
    .map(parentEstimation => {
      const estimationWithAverage = estimationItemWithAverage(
        parentEstimation,
        roundingOptions
      );
      const estimationWithSubItems = {
        ...estimationWithAverage,
        subItems: calculateTimesForParent(
          getEstimationSubItems(estimationWithAverage, subItems).sort(
            sortByRank
          )
        ),
      };

      return calculateEstimationsTotalCosts(estimationWithSubItems);
    })
    .sort(sortByRank);
};
