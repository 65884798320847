import React, { useEffect } from 'react';
import { bool } from 'prop-types';
import { connect } from 'react-redux';

import { fetchUsers, fetchUserById } from '../redux/thunk/users';
import { handleOrganizationFetch } from '../redux/thunk/organization';
import { Routes } from './routes';
import { ROUTES } from './constants';

const mapStateToProps = ({ router }) => ({
  isPreview:
    router.location.pathname.split('/')[1] ===
    ROUTES.ESTIMATION_PREVIEW.split('/')[1],
});

const mapDispatchToProps = {
  fetchUsers,
  fetchUserById,
  handleOrganizationFetch,
};

const AppRoutesContainer = ({ isPreview, ...actions }) => {
  useEffect(() => {
    if (!isPreview) {
      actions.fetchUsers();
      actions.fetchUserById();
      actions.handleOrganizationFetch();
    }
  }, []);

  return <Routes />;
};

AppRoutesContainer.defaultProps = {
  isPreview: false,
};

AppRoutesContainer.propTypes = {
  isPreview: bool,
};

export const AppRoutes = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppRoutesContainer);
