import { authRequest, apikoApi } from './utils';

export const fetchEstimationTasks = estimationId =>
  authRequest({
    method: 'GET',
    url: `/estimations/${estimationId}/estimationsTasks`,
  }).then(({ data: { estimationtasks } }) => estimationtasks);

export const createEstimationTask = ({ estimationId, sectionId, ...rest }) =>
  authRequest({
    method: 'POST',
    url: `/estimations/${estimationId}/sections/${sectionId}/estimationstask`,
    data: { ...rest, estimationId, sectionId },
  }).then(({ data: { estimation } }) => estimation);

export const updateEstimationTask = (
  estimationId,
  sectionId,
  estimationTaskId,
  modifier
) =>
  authRequest({
    method: 'PATCH',
    url: `/estimations/${estimationId}/sections/${sectionId}/estimationstask/${estimationTaskId}`,
    data: modifier,
  }).then(({ data: { estimation } }) => estimation);

export const deleteEstimationTask = (
  estimationId,
  sectionId,
  estimationTaskId
) =>
  authRequest({
    method: 'DELETE',
    url: `/estimations/${estimationId}/sections/${sectionId}/estimationstask/${estimationTaskId}`,
  }).then(({ data: { estimation } }) => estimation);

/**
 * createEstimationComment :: (ID, Object) -> Promise Error Object
 */
export const createEstimationComment = (
  isAuthorized,
  estimationId,
  commentData
) => {
  const request = isAuthorized ? authRequest : apikoApi;

  return request({
    method: 'POST',
    url: `/comments/item/${estimationId}`,
    data: commentData,
  }).then(({ data }) => data);
};
/**
 * updateEstimationComment :: (ID, Object) -> Promise Error Object
 */
export const updateEstimationComment = (
  isAuthorized,
  commentId,
  commentData
) => {
  const request = isAuthorized ? authRequest : apikoApi;

  return request({
    method: 'PATCH',
    url: `/comments/${commentId}`,
    data: commentData,
  }).then(({ data }) => data);
};
/**
 * fetchEstimationComments :: ID -> Promise Error Object
 */
export const fetchEstimationComments = estimationId =>
  apikoApi({
    method: 'GET',
    url: `/comments/item/${estimationId}`,
  }).then(({ data }) => data);
/**
 *  setViewedEstimationComment :: (ID, Array) -> Promise Error Object
 */
export const setViewedEstimationComment = (estimationId, comments) =>
  authRequest({
    method: 'PATCH',
    url: `/comments/view/${estimationId}`,
    data: { comments },
  }).then(({ data }) => data);
