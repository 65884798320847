import { authRequest } from './utils';

export const createProject = data =>
  authRequest({
    method: 'POST',
    url: `/projects/create`,
    data,
  }).then(({ data: { project } }) => project);

export const fetchProjects = params =>
  authRequest({
    method: 'GET',
    url: '/projects',
    params,
  }).then(({ data }) => data);

export const fetchProject = projectId =>
  authRequest({
    method: 'GET',
    url: `/projects/${projectId}`,
  }).then(({ data: { project } }) => project);

export const deleteProject = projectId =>
  authRequest({
    method: 'DELETE',
    url: `/projects/${projectId}`,
  }).then(({ data }) => data);

export const fetchProjectNamesList = () =>
  authRequest({
    method: 'GET',
    url: `/projects/shortlist`,
  }).then(({ data: { projects } }) => projects);

export const fetchLibraryData = () =>
  authRequest({
    method: 'GET',
    url: '/estimations/library',
  }).then(({ data }) => data);

export const fetchTechStack = key =>
  authRequest({
    method: 'GET',
    url: `/techstacks/${key}/stacks`,
  }).then(({ data: { stacks } }) => stacks);

export const updateProject = (projectId, modifier) =>
  authRequest({
    method: 'PATCH',
    url: `/projects/${projectId}`,
    data: modifier,
  }).then(({ data }) => data);

export const addCustomerInfo = (projectId, data) =>
  authRequest({
    method: 'POST',
    url: `/projects/${projectId}/customers`,
    data,
  }).then(({ data: { project } }) => project);

export const updateCustomerInfo = (projectId, customerId, modifier) =>
  authRequest({
    method: 'PATCH',
    url: `/projects/${projectId}/customers/${customerId}`,
    data: modifier,
  }).then(({ data: { project } }) => project);

export const deleteCustomerInfo = (projectId, customerId) =>
  authRequest({
    method: 'DELETE',
    url: `/projects/${projectId}/customers/${customerId}`,
  }).then(({ data: { project } }) => project);

export const createProjectComment = (projectId, text, author) =>
  authRequest({
    method: 'POST',
    url: `/projects/${projectId}/comments`,
    data: {
      text,
      author,
    },
  }).then(({ data: { comments } }) => comments);

export const fetchProjectComments = projectId =>
  authRequest({
    method: 'GET',
    url: `/projects/${projectId}/comments`,
  }).then(({ data: { comments } }) => comments);

export const updateProjectComment = (projectId, commentId, text) =>
  authRequest({
    method: 'PATCH',
    url: `/projects/${projectId}/comments/${commentId}`,
    data: {
      text,
    },
  }).then(({ data: { comments } }) => comments);

export const deleteProjectComment = (projectId, commentId) =>
  authRequest({
    method: 'DELETE',
    url: `/projects/${projectId}/comments/${commentId}`,
  }).then(({ data: { comments } }) => comments);
