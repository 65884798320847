import { createAction } from 'redux-actions';

const types = {
  FETCH_FEEDBACK: 'FETCH_FEEDBACK',
  POST_FEEDBACK: 'POST_FEEDBACK',
  CLEAR_FEEDBACKS: 'CLEAR_FEEDBACKS',
  FETCH_FEEDBACK_ISSUES: 'FETCH_FEEDBACK_ISSUES',
  FETCH_PROJECT_FEEDBACKS: 'FETCH_PROJECT_FEEDBACKS',
};

export const fetchFeedbackRequest = createAction(
  `${types.FETCH_FEEDBACK}_REQUEST`
);
export const fetchFeedbackSuccess = createAction(
  `${types.FETCH_FEEDBACK}_SUCCESS`
);
export const fetchFeedbackFailure = createAction(
  `${types.FETCH_FEEDBACK}_FAILURE`
);

export const postFeedbackRequest = createAction(
  `${types.POST_FEEDBACK}_REQUEST`
);
export const postFeedbackSuccess = createAction(
  `${types.POST_FEEDBACK}_SUCCESS`
);
export const postFeedbackFailure = createAction(
  `${types.POST_FEEDBACK}_FAILURE`
);

export const fetchFeedbackIssuesRequest = createAction(
  `${types.FETCH_FEEDBACK_ISSUES}_REQUEST`
);
export const fetchFeedbackIssuesSuccess = createAction(
  `${types.FETCH_FEEDBACK_ISSUES}_SUCCESS`
);
export const fetchFeedbackIssuesFailure = createAction(
  `${types.FETCH_FEEDBACK_ISSUES}_FAILURE`
);

export const fetchProjectFeedbacksRequest = createAction(
  `${types.FETCH_PROJECT_FEEDBACKS}_REQUEST`
);
export const fetchProjectFeedbacksSuccess = createAction(
  `${types.FETCH_PROJECT_FEEDBACKS}_SUCCESS`
);
export const fetchProjectFeedbacksFailure = createAction(
  `${types.FETCH_PROJECT_FEEDBACKS}_FAILURE`
);

export const clearFeedbacks = createAction(types.CLEAR_FEEDBACKS);
