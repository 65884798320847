import { combineReducers } from 'redux';

import { autopilotProjectsReducer } from './autopilot-projects';
import { autopilotIssuesReducer } from './autopilot-issues';
import { autopilotTeamReducer } from './autopilot-team';
import { autopilotIntegrationsReducer } from './autopilot-integrations';
import { autopilotHubstaffReducer } from './autopilot-hubstaff';
import { autopilotProjectReducer } from './autopilot-project';

export const autopilotReducer = combineReducers({
  projects: autopilotProjectsReducer,
  project: autopilotProjectReducer,
  issues: autopilotIssuesReducer,
  team: autopilotTeamReducer,
  integrations: autopilotIntegrationsReducer,
  hubstaff: autopilotHubstaffReducer,
});
