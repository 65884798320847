import { authRequest } from './utils';

export const fetchEstimationSections = estimationId =>
  authRequest({
    method: 'GET',
    url: `/estimations/${estimationId}/sections`,
  }).then(({ data: { sections } }) => sections);

export const createEstimationSection = estimationId =>
  authRequest({
    method: 'POST',
    url: `/estimations/${estimationId}/sections`,
    data: { estimationId },
  }).then(({ data: { section } }) => section);

export const updateEstimationSection = (estimationId, sectionId, modifier) =>
  authRequest({
    method: 'PATCH',
    url: `/estimations/${estimationId}/sections/${sectionId}`,
    data: modifier,
  }).then(({ data: { section } }) => section);

export const copyEstimationSection = (estimationId, sectionId) =>
  authRequest({
    method: 'POST',
    url: `/estimations/${estimationId}/sections/copy`,
    data: { estimationId, sectionId },
  }).then(({ data }) => data);

export const deleteEstimationSection = (estimationId, sectionId) =>
  authRequest({
    method: 'DELETE',
    url: `/estimations/${estimationId}/sections/${sectionId}`,
  }).then(({ data: { section } }) => section);
