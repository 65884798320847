import fileDownload from 'js-file-download';

import { authRequest } from './utils';

/**
 * refreshAutopilotProjects :: () -> Promise Error Project
 */
export const refreshAutopilotProjects = () =>
  authRequest({
    method: 'GET',
    url: 'autopilot/refreshproject',
  }).then(({ data }) => data);

/**
 * refreshAutopilotProjects :: () -> Promise Error Project
 */
export const getLastRefreshProject = () =>
  authRequest({
    method: 'GET',
    url: 'autopilot/lastrefresh',
  }).then(({ data }) => data);
/**
 * fetchProject :: () -> Promise Error Project
 */
export const fetchAutopilotProject = id =>
  authRequest({
    method: 'GET',
    url: `autopilot/${id}`,
  }).then(({ data }) => data);

/**
 * fetchProjectIssues :: Object -> Promise Error Project
 */
export const fetchIssues = ({ id, params }) =>
  authRequest({
    params,
    method: 'GET',
    url: `autopilot/${id}/issues`,
  }).then(({ data }) => data);

export const exportIssuesToCSV = ({ id, params, projectName }) =>
  authRequest({
    params,
    method: 'GET',
    url: `autopilot/${id}/export/csv/issues`,
  }).then(({ data }) => fileDownload(data, `${projectName}_issues.csv`));

/**
 * fetchProjectIssues :: Object -> Promise Error Project
 */
export const fetchClosedIssuesGraph = ({ id, type, projectId }) =>
  authRequest({
    method: 'GET',
    params: { type, projectId },
    url: `autopilot/${id}/stats/closed-issues`,
  }).then(({ data }) => data);

/**
 * fetchProjectIssues :: Object -> Promise Error Project
 */
export const fetchAlerts = ({ id, params }) =>
  authRequest({
    params,
    method: 'GET',
    url: `autopilot/${id}/alerts`,
  }).then(({ data }) => data);

/**
 * fetchProjectIssues :: Object -> Promise Error Project
 */
export const fetchTeam = ({ id, type, projectId }) =>
  authRequest({
    method: 'GET',
    url: `autopilot/${id}/team?${
      type ? `type=${type}&projectId=${projectId}` : ''
    }`,
  }).then(({ data }) => data);

export const fetchIssuesTime = ({ id, type, projectId }) =>
  authRequest({
    method: 'GET',
    url: `autopilot/${id}/stats/spent-estimate-chart?${
      type ? `type=${type}&projectId=${projectId}` : ''
    }`,
  }).then(({ data }) => data);

export const fetchConnectHubstaffProject = ({ id, hubstaffProjectId }) =>
  authRequest({
    method: 'POST',
    url: `autopilot/${id}/hubstaff/connect`,
    data: {
      hubstaffProjectId,
    },
  }).then(({ data }) => data);

export const fetchIntegrations = ({ id }) =>
  authRequest({
    method: 'GET',
    url: `autopilot/${id}/integrations`,
  }).then(({ data }) => data);

export const fetchInitializeHubstaffIntegration = ({ id }) =>
  authRequest({
    method: 'POST',
    url: `autopilot/${id}/integrations/hubstaff/initialize`,
  }).then(({ data }) => data);

export const fetchUnconnectHubstaffProject = ({
  projectId,
  hubstaffProjectId,
}) =>
  authRequest({
    method: 'POST',
    url: `autopilot/${projectId}/integrations/hubstaff/${hubstaffProjectId}/unconnect`,
  }).then(({ data }) => data);

export const fetchProjectlabels = params =>
  authRequest({
    method: 'GET',
    url: `autopilot/${params.id}/labels`,
    // { id, type, state, projectId, assigneeId }
    params,
  }).then(({ data }) => data);
