import { createAction } from 'redux-actions';

const types = {
  FETCH_SECTIONS: 'FETCH_SECTIONS',
  CREATE_SECTION: 'CREATE_SECTION',
  UPDATE_SECTION: 'UPDATE_SECTION',
  COPY_SECTION: 'COPY_SECTION',
  DELETE_SECTION: 'DELETE_SECTION',
  CLEAR_SECTIONS: 'CLEAR_SECTIONS',
};

export const fetchSectionsRequest = createAction(
  `${types.FETCH_SECTIONS}_REQUEST`
);
export const fetchSectionsSuccess = createAction(
  `${types.FETCH_SECTIONS}_SUCCESS`
);
export const fetchSectionsFailure = createAction(
  `${types.FETCH_SECTIONS}_FAILURE`
);

export const createSectionRequest = createAction(
  `${types.CREATE_SECTION}_REQUEST`
);
export const createSectionSuccess = createAction(
  `${types.CREATE_SECTION}_SUCCESS`
);
export const createSectionFailure = createAction(
  `${types.CREATE_SECTION}_FAILURE`
);

export const updateSectionSuccess = createAction(
  `${types.UPDATE_SECTION}_SUCCESS`
);
export const updateSectionFailure = createAction(
  `${types.UPDATE_SECTION}_FAILURE`
);

export const copySectionRequest = createAction(`${types.COPY_SECTION}_REQUEST`);
export const copySectionSuccess = createAction(`${types.COPY_SECTION}_SUCCESS`);
export const copySectionFailure = createAction(`${types.COPY_SECTION}_FAILURE`);

export const deleteSectionSuccess = createAction(
  `${types.DELETE_SECTION}_SUCCESS`
);
export const deleteSectionFailure = createAction(
  `${types.DELETE_SECTION}_FAILURE`
);

export const clearSections = createAction(`${types.CLEAR_SECTIONS}`);
