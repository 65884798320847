import * as R from 'ramda';
import { batchActions } from 'redux-batched-actions';
import { push } from 'connected-react-router';
// import messageAlert from 'antd/lib/message';

import { ROUTES } from '../routes';
import { setInitialLoad } from './actions/global';
import { isUnauthorizedError } from '../api/utils';
import { store } from './store';
import { signInFailure, signInSuccess } from './actions/auth';

// const showErrorAlert = ({ message = 'Unexpected Error' }) =>
//   messageAlert.error(message);

export const handleApikoHttpResponse = error => {
  if (R.pathEq(['response', 'status'], 406)(error)) {
    store.dispatch(push(ROUTES.SIGN_IN));
  } else if (isUnauthorizedError(error)) {
    // Logout current user
    store.dispatch(batchActions([signInFailure(), setInitialLoad(false)]));
  } else {
    // Sign in current user
    const { initialLoad, auth } = store.getState();

    if (!auth.isAuthorized || initialLoad) {
      store.dispatch(batchActions([signInSuccess(), setInitialLoad(false)]));
    }

    // if (error) showErrorAlert(error);
  }
};

export const handle404HttpResponse = error => {
  if (R.pathEq(['response', 'status'], 404, error)) {
    store.dispatch(push(ROUTES.NOT_FOUND));
  }
  return error;
};
