export const modalNames = {
  CROP_IMAGE: 'CROP_IMAGE',
  RESTORE_PASSWORD: 'RESTORE_PASSWORD',
  CREATE_ESTIMATION: 'CREATE_ESTIMATION',
  COPY_ESTIMATION: 'COPY_ESTIMATION',
  MOVE_ESTIMATION: 'MOVE_ESTIMATION',
  CREATE_CONTACT: 'CREATE_CONTACT',
  MANAGE_USERS: 'MANAGE_USERS',
  EDIT_ESTIMATION: 'EDIT_ESTIMATION',
  ESTIMATION_ITEM_DETAILS: 'ESTIMATION_ITEM_DETAILS',
  CREATE_FILES: 'CREATE_FILES',
  CREATE_PROJECT: 'CREATE_PROJECT',
  TALENT_WORKLOAD: 'TALENT_WORKLOAD',
  ADD_PROJECT_TALENTS: 'ADD_PROJECT_TALENTS',
  EDIT_COMMENT: 'EDIT_COMMENT',
  FEEDBACK_DETAILS: 'FEEDBACK_DETAILS',
  FEEDBACKS_LIST_DETAILS: 'FEEDBACKS_LIST_DETAILS',
  CHANGE_ALLOWED_PROJECTS: 'CHANGE_ALLOWED_PROJECTS',
};
