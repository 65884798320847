import React from 'react';
import { string } from 'prop-types';

import { Compare as StyledCompare } from './styled-components';

export const Compare = ({ humanTotalTimeSpent, humanTimeEstimate }) => (
  <StyledCompare>
    {humanTotalTimeSpent && (
      <StyledCompare.Item>
        <StyledCompare.Label>Spent:</StyledCompare.Label>
        <StyledCompare.Value>{humanTotalTimeSpent}</StyledCompare.Value>
      </StyledCompare.Item>
    )}
    {humanTimeEstimate && (
      <StyledCompare.Item>
        <StyledCompare.Label>Est:</StyledCompare.Label>
        <StyledCompare.Value>{humanTimeEstimate}</StyledCompare.Value>
      </StyledCompare.Item>
    )}
  </StyledCompare>
);

Compare.propTypes = {
  // humanTimeEstimate and humanTotalTimeSpent will be a human readable strings like "1h 59m"
  humanTimeEstimate: string,
  humanTotalTimeSpent: string,
};

Compare.defaultProps = {
  humanTimeEstimate: null,
  humanTotalTimeSpent: null,
};
