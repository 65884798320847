import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from '../history';

import { authReducers } from './auth';
import { usersReducers } from './users';
import { initialLoadReducer } from './global';
import { autopilotReducer } from './autopilot';
import { invitationsReducers } from './invitations';
import { profileSettingsReducers } from './profile-settings';
import { organizationReducer } from './organization';
import { projectsReducer } from './projects';
import { estimationsReducer } from './estimations';
import { estimationTasksReducer } from './estimationTasks';
import { estimationSectionsReducer } from './sections';
import { libraryReducer } from './library';
import { attachmentsReducer } from './attachments';
import { searchReducer } from './search';
import { feedbacksReducers } from './feedbacks';
import { modalReducer } from '../../modals/modal-reducer';

export const reducers = combineReducers({
  router: connectRouter(history),
  auth: authReducers,
  users: usersReducers,
  autopilot: autopilotReducer,
  initialLoad: initialLoadReducer,
  invitationsUser: invitationsReducers,
  profileSettings: profileSettingsReducers,
  organization: organizationReducer,
  modal: modalReducer,
  attachments: attachmentsReducer,
  projects: projectsReducer,
  library: libraryReducer,
  estimations: estimationsReducer,
  estimationTasks: estimationTasksReducer,
  estimationSections: estimationSectionsReducer,
  search: searchReducer,
  feedbacks: feedbacksReducers,
});
