import { mergeDeepLeft } from 'ramda';
import { handleActions } from 'redux-actions';

import {
  updateAutopilotProject,
  refreshProjectsRequest,
  handleDateFromGraph,
  refreshAutopilotProjectsSuccess,
} from '../../actions/autopilot';

const defaultState = {
  items: {},
  summary: null,
  isFetchingProjects: false,
  selectedDateFromGraph: null,
  lastupdatedAt: '',
  isRefresh: false,
};

const refreshProjectsSuccessReducer = (state, { payload }) => ({
  ...state,
  isRefresh: false,
  lastupdatedAt: payload,
});

const updateProjectReducer = (
  state,
  { payload: { projectId, ...changes } }
) => ({
  ...state,
  items: {
    ...state.items,
    [projectId]: {
      ...state.items[projectId],
      ...changes,
    },
  },
});

const handleSelectedDateFromGraph = (state, { payload }) => ({
  ...state,
  selectedDateFromGraph: payload,
});

export const autopilotProjectsReducer = handleActions(
  {
    [updateAutopilotProject]: updateProjectReducer,
    [refreshAutopilotProjectsSuccess]: refreshProjectsSuccessReducer,
    [refreshProjectsRequest]: mergeDeepLeft({ isRefresh: true }),
    [handleDateFromGraph]: handleSelectedDateFromGraph,
  },
  defaultState
);
