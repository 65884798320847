import styled from 'styled-components';
import React from 'react';
import { Spin } from 'antd';

import { spinnerStyles } from '../../styles';

export const Spinner = styled.div`
  ${spinnerStyles};
  position: ${props => props.position || 'absolute'};
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  margin-top: -${props => props.width / 2}px;
  margin-left: -${props => props.width / 2}px;
  border: ${props => props.borderWidth}px solid transparent;
  border-top-color: ${props => props.theme.colors.tangerinePrimary};
  z-index: 9999;
`;

Spinner.defaultProps = {
  width: 50,
  height: 50,
  borderWidth: 4,
};

Spin.setDefaultIndicator(<Spinner />);

const SpinnerBackground = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 9999;
`;

export const SpinnerWithBackground = () => (
  <>
    <SpinnerBackground />
    <Spinner />
  </>
);

export const LoadingMore = styled.div`
  ${spinnerStyles};
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  position: ${props => props.position || 'static'};
  margin: ${props => props.margin};
  border: ${props => props.borderWidth}px solid transparent;
  border-top-color: ${props =>
    props.color || props.theme.colors.tangerinePrimary};
`;

LoadingMore.defaultProps = {
  width: 30,
  height: 30,
  borderWidth: 3,
  margin: '5px auto',
};
