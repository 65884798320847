import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { theme } from './theme';
import { mediaSize } from './media';

export const spinnerStyles = css`
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  animation: spinner 0.8s ease infinite;
`;

export const btnSpinner = css`
  ${spinnerStyles};
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border: 2px solid transparent;
  border-top-color: ${theme.colors.white};
  border-bottom-color: ${theme.colors.white};
`;

export const tangerineGradient = css`
  background: linear-gradient(
    to left,
    ${theme.colors.tangerineSecond},
    ${theme.colors.tangerinePrimary}
  );
`;

export const tangerineGradientRevert = css`
  background: linear-gradient(
    to right,
    ${theme.colors.tangerineSecond},
    ${theme.colors.tangerinePrimary}
  );
`;

export const littleButton = css`
  text-align: center;
  border-radius: 12.7px;
  border: solid 1.1px ${theme.colors.borderSilver};
  padding: 0 10px;
  line-height: 20px;
  cursor: pointer;
  min-width: fit-content;
  background: none;
  outline: none;
`;

export const hideOverflowText = css`
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const widgetCss = css`
  border-radius: 4px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.borderLightSilver};
  box-shadow: 0 2px 3px ${props => props.theme.colors.shadowSilver};
`;

export const cardCss = css`
  padding: 22px 24px;
  ${widgetCss};

  @media (max-width: ${mediaSize.phone}) {
    padding: 11px 12px;
  }
`;

export const Stack = styled.div`
  > * {
    margin-bottom: ${prop('margin')}px;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;

export const HeaderCell = styled.th`
  padding: 15px 0;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
`;
