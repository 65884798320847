import { apikoApi, authRequest } from './utils';

/**
 * inviteUser :: Object -> Promise Error Object
 */
export const inviteUser = data =>
  authRequest({
    method: 'POST',
    url: '/invitations/invite',
    data,
  }).then(({ data: { invitationSecret } }) => invitationSecret);

export const fetchInvitationsUserInfo = _id =>
  apikoApi({
    method: 'GET',
    url: `/invitations/${_id}`,
  }).then(({ data: { user } }) => user);

export const acceptInvitations = (invitationSecret, data) =>
  apikoApi({
    method: 'POST',
    url: `/invitations/${invitationSecret}/accept`,
    data,
  }).then(response => response);
