import * as R from 'ramda';
import { handleActions, combineActions } from 'redux-actions';
import {
  fetchEstimationsRequest,
  fetchEstimationsSuccess,
  fetchEstimationsFailure,
  createEstimationRequest,
  createEstimationFailure,
  copyEstimationRequest,
  copyEstimationSuccess,
  copyEstimationFailure,
  moveEstimationRequest,
  moveEstimationSuccess,
  moveEstimationFailure,
  fetchEstimationRequest,
  fetchEstimationSuccess,
  fetchEstimationFailure,
  updateEstimationSuccess,
  updateEstimationFailure,
  getEstimationPreviewTokenSuccess,
  clearEstimationsData,
  clearEstimationData,
  fetchEstimationPreviewRequest,
} from '../actions/estimations';

const defaultState = {
  error: null,
  isFetching: false,
  isCreating: false,
  items: [],
  currentItem: {},
};

const failureReducer = (state, { payload }) => ({
  ...state,
  error: payload,
  isFetching: false,
});

const fetchEstimationsSuccessReducer = (state, { payload }) => ({
  ...state,
  error: null,
  isFetching: false,
  isCreating: false,
  items: payload,
});

const fetchEstimationSuccessReducer = (state, { payload }) => ({
  ...state,
  error: null,
  isFetching: false,
  currentItem: payload,
});

const getEstimationPreviewTokenSuccessReducer = (state, { payload }) => ({
  ...state,
  currentItem: { ...state.currentItem, ...payload },
});
const clearEstimationsDataReducer = state => ({
  ...state,
  error: null,
  isFetching: false,
  isCreating: false,
  items: [],
});

const clearEstimationDataReducer = state => ({
  ...state,
  error: null,
  isFetching: false,
  isCreating: false,
  currentItem: {},
});

export const estimationsReducer = handleActions(
  {
    [combineActions(
      fetchEstimationsRequest,
      fetchEstimationRequest,
      fetchEstimationPreviewRequest
    )]: R.mergeDeepLeft({ isFetching: true }),
    [combineActions(
      createEstimationRequest,
      copyEstimationRequest,
      moveEstimationRequest
    )]: R.mergeDeepLeft({ isCreating: true }),
    [combineActions(
      fetchEstimationsFailure,
      createEstimationFailure,
      copyEstimationFailure,
      moveEstimationFailure,
      fetchEstimationFailure,
      updateEstimationFailure
    )]: failureReducer,
    [combineActions(
      fetchEstimationsSuccess,
      copyEstimationSuccess,
      moveEstimationSuccess
    )]: fetchEstimationsSuccessReducer,
    [combineActions(
      fetchEstimationSuccess,
      updateEstimationSuccess
    )]: fetchEstimationSuccessReducer,
    [getEstimationPreviewTokenSuccess]: getEstimationPreviewTokenSuccessReducer,
    [clearEstimationsData]: clearEstimationsDataReducer,
    [clearEstimationData]: clearEstimationDataReducer,
  },
  defaultState
);
