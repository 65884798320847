import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';

import history from './redux/history';
import { store } from './redux';
import { theme } from './styles';
import { AppRoutes } from './routes/route-container';
import { Spinner } from './components/ui-helpers';
import { GlobalStyle } from './styles/global-styles';

export default () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Suspense fallback={<Spinner />}>
        <ConnectedRouter history={history}>
          <GlobalStyle />
          <AppRoutes />
        </ConnectedRouter>
      </Suspense>
    </ThemeProvider>
  </Provider>
);
