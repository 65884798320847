import { authRequest } from './utils';

/**
 * fetchOrganizationSettings :: _ -> Promise Error Object
 */
export const fetchOrganizationSettings = () =>
  authRequest({
    method: 'GET',
    url: '/organizations/settings',
  }).then(({ data }) => data);

/**
 * updateProject :: (ID, Object) -> Promise Error Object
 */
export const updateOrganizationSettings = modifier =>
  authRequest({
    method: 'PATCH',
    url: '/organizations',
    data: modifier,
  }).then(({ data }) => data);

export const uploadOrganizationsLogo = logo =>
  authRequest({
    method: 'PATCH',
    url: 'organizations/logo',
    data: logo,
  }).then(({ data }) => data);

export const createAdditionalCostForOrganization = data =>
  authRequest({
    method: 'POST',
    url: 'organizations/additional-cost',
    data,
  });
