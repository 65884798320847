import * as R from 'ramda';
import { combineActions, handleActions } from 'redux-actions';

import {
  fetchSectionsRequest,
  fetchSectionsSuccess,
  fetchSectionsFailure,
  updateSectionSuccess,
  updateSectionFailure,
  createSectionRequest,
  createSectionSuccess,
  createSectionFailure,
  copySectionRequest,
  copySectionSuccess,
  copySectionFailure,
  deleteSectionSuccess,
  deleteSectionFailure,
  clearSections,
} from '../actions/sections';
import { arrayToObject } from '../../utils/general';

const defaultState = {
  error: null,
  isFetching: false,
  isCreateRequest: false,
  items: {},
};

const failureReducer = (state, { payload }) => ({
  ...state,
  error: payload,
  isFetching: false,
  isCreateRequest: false,
});

const fetchSectionsSuccessReducer = (state, { payload }) => ({
  ...state,
  error: null,
  isFetching: false,
  items: arrayToObject(payload),
});

const createSectionSuccessReducer = (state, { payload }) => ({
  ...state,
  error: null,
  isCreateRequest: false,
  items: {
    ...state.items,
    [payload._id]: {
      ...payload,
      isEditing: true,
    },
  },
});

const updateSectionSuccessReducer = (state, { payload }) => ({
  ...state,
  error: null,
  items: {
    ...state.items,
    [payload._id]: {
      ...state.items[payload._id],
      ...payload,
    },
  },
});

const copySectionRequestReducer = ({ items, ...state }, { payload }) => ({
  ...state,
  error: null,
  items: {
    ...items,
    [payload]: {
      ...items[payload],
      isCopying: true,
    },
  },
});

const copySectionSuccessReducer = ({ items, ...state }, { payload }) => ({
  ...state,
  error: null,
  items: {
    ...R.map(item => R.omit(['isCopying'], item))(items),
    [payload._id]: payload,
  },
});

const deleteSectionSuccessReducer = (state, { payload }) => ({
  ...state,
  error: null,
  items: R.dissoc(payload._id, state.items),
});

const clearSectionsReducer = state => ({
  ...state,
  error: null,
  items: {},
});

export const estimationSectionsReducer = handleActions(
  {
    [fetchSectionsRequest]: R.mergeDeepLeft({ isFetching: true }),
    [createSectionRequest]: R.mergeDeepLeft({ isCreateRequest: true }),
    [combineActions(
      fetchSectionsFailure,
      updateSectionFailure,
      createSectionFailure,
      copySectionFailure,
      deleteSectionFailure
    )]: failureReducer,
    [fetchSectionsSuccess]: fetchSectionsSuccessReducer,
    [updateSectionSuccess]: updateSectionSuccessReducer,
    [createSectionSuccess]: createSectionSuccessReducer,
    [copySectionRequest]: copySectionRequestReducer,
    [copySectionSuccess]: copySectionSuccessReducer,
    [deleteSectionSuccess]: deleteSectionSuccessReducer,
    [clearSections]: clearSectionsReducer,
  },
  defaultState
);
