import { createActions } from 'redux-actions';

/**
 * Generates three actions for typical API request:
 * ${type}Request, ${type}Success, ${type}Failure
 * Check meeting-notes for examples
 *
 * generateApiActions :: String -> Object
 */
export const generateApiActions = type =>
  createActions({
    [`${type}_REQUEST`]: a => a,
    [`${type}_SUCCESS`]: a => a,
    [`${type}_FAILURE`]: a => a,
  });
