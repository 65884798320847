import { createAction } from 'redux-actions';

const types = {
  SEARCH_TASKS: 'SEARCH_TASKS',
  SEARCH_PROJECTS: 'SEARCH_PROJECTS',
  CHANGE_SEARCH_TYPE: 'CHANGE_SEARCH_TYPE',
};

export const searchProjectsRequest = createAction(
  `${types.SEARCH_PROJECTS}_REQUEST`
);
export const searchProjectsSuccess = createAction(
  `${types.SEARCH_PROJECTS}_SUCCESS`
);
export const searchProjectsFailure = createAction(
  `${types.SEARCH_PROJECTS}_FAILURE`
);

export const searchTasksRequest = createAction(`${types.SEARCH_TASKS}_REQUEST`);
export const searchTasksSuccess = createAction(`${types.SEARCH_TASKS}_SUCCESS`);
export const searchTasksFailure = createAction(`${types.SEARCH_TASKS}_FAILURE`);

export const changeSearchType = createAction(`${types.CHANGE_SEARCH_TYPE}`);
