import fileDownload from 'js-file-download';
import { authRequest } from './utils';

export const fetchProjectEstimations = projectId =>
  authRequest({
    method: 'GET',
    url: `/estimations/${projectId}`,
  }).then(({ data: { estimations } }) => estimations);

export const fetchProjectEstimation = estimationId =>
  authRequest({
    method: 'GET',
    url: `/estimations/${estimationId}/getestimation-by-id`,
  }).then(({ data: { estimation } }) => estimation);

export const createProjectEstimation = data =>
  authRequest({
    method: 'POST',
    url: `/estimations`,
    // data: { projectId, name, description, estimationType, rate, additionalCosts }
    data,
  }).then(({ data: { estimation } }) => estimation);

export const copyProjectEstimation = (data, currentProjectId) =>
  authRequest({
    method: 'POST',
    url: `/estimations/copy`,
    // data: { projectId, estimationId, rate, additionalCosts }
    data,
  }).then(() => fetchProjectEstimations(currentProjectId));

export const moveProjectEstimation = (data, currentProjectId) =>
  authRequest({
    method: 'POST',
    url: `/estimations/move`,
    // data: { projectId, estimationId }
    data,
  }).then(() => fetchProjectEstimations(currentProjectId));

export const updateEstimation = (estimationId, modifier) =>
  authRequest({
    method: 'PATCH',
    url: `/estimations/${estimationId}`,
    data: modifier,
  }).then(({ data }) => data);

export const updateTeamMates = (estimationId, modifier) =>
  authRequest({
    method: 'PATCH',
    url: `/estimations/teammates/${estimationId}`,
    data: modifier,
  }).then(({ data }) => data);

export const createEstimationMilestone = (estimationId, estimationTaskId) =>
  authRequest({
    method: 'POST',
    url: `/estimations/${estimationId}/milestones`,
    data: { estimationTaskId },
  }).then(({ data: { estimationTask } }) => ({ estimationTask }));
/*
 * exportProjectAsCSV :: ({ ID, String }) -> Promise Error Project
 *
 */
export const exportEstimationAsCSV = (estimationId, name) =>
  authRequest({
    method: 'GET',
    url: `/estimations/export/csv/${estimationId}`,
  }).then(({ data }) => fileDownload(data, `${name}.csv`));

/*
 * exportProjectAsCSV :: ({ ID, String }) -> Promise Error Project
 *
 */
export const getEstimationPreviewToken = estimationId =>
  authRequest({
    method: 'POST',
    url: `/estimations/${estimationId}/get-preview-token`,
  }).then(({ data }) => data);

/**
 * fetchEstimationPreview :: String -> Promise Error Project
 */
export const fetchEstimationPreview = (previewToken, query) =>
  authRequest({
    method: 'GET',
    url: `/estimations/estimation/preview?previewToken=${previewToken}${query}`,
  }).then(({ data: { estimation } }) => estimation);

/**
 * Delete Estimation :: String -> Promise Error Project
 */
export const deleteEstimation = estimationId =>
  authRequest({
    method: 'DELETE',
    url: `/estimations/${estimationId}`,
  }).then(({ data }) => data);
/**
 * updateCustomerNotification :: (ID, Object) -> Promise Error Object
 */
export const updateCustomerNotification = (
  estimationId,
  customerId,
  modifier
) =>
  authRequest({
    method: 'PATCH',
    url: `/estimations/${estimationId}/customers/${customerId}`,
    data: modifier,
  }).then(({ data: { estimation } }) => estimation);
/**
 * fetchEstimationForExport :: ID -> Promise Error Project
 */
export const fetchEstimationForExport = (projectId, query) =>
  authRequest({
    method: 'GET',
    url: `/estimations/export-data/${projectId}${query}`,
  }).then(({ data: { estimation } }) => estimation);

export const updatetEstimationsCost = (estimationId, data) =>
  authRequest({
    method: 'POST',
    url: `/estimations/${estimationId}/update-estimations-cost`,
    data,
  }).then(({ data: { estimationTasks } }) => estimationTasks);
