import React from 'react';
import { number } from 'prop-types';

import { Progress as StyledProgress } from './styled-components';
import { clamp } from '../../utils';

const NORMAL = 'normal';
const WARNING = 'warning';
const DANGER = 'danger';

export const PROGRESS_STATUSES = { NORMAL, WARNING, DANGER };

const getTimeTrackingStatus = percent => {
  if (percent > 100) return DANGER;
  if (percent > 90) return WARNING;

  return NORMAL;
};

export const Progress = ({
  // TODO: height prop is temporary. Is it better to pass className and use styled(...)
  height,
  timeEstimate,
  totalTimeSpent,
  timeSpendPercentage,
}) => {
  const spendPercent = clamp(0, timeSpendPercentage, 100);
  const isOverLimited = totalTimeSpent > timeEstimate;
  const estimatePercent = isOverLimited
    ? (timeEstimate / totalTimeSpent) * 100
    : 0;

  return (
    <StyledProgress height={height}>
      <StyledProgress.Bar
        role="progressbar"
        aria-valuemin="0"
        aria-valuemax="100"
        aria-valuenow={spendPercent}
        percent={spendPercent}
        status={getTimeTrackingStatus(timeSpendPercentage)}
      />
      {isOverLimited && !!timeEstimate && (
        <StyledProgress.Estimate
          role="progressbar"
          aria-valuemin="0"
          aria-valuemax="100"
          aria-valuenow={estimatePercent}
          percent={estimatePercent}
        />
      )}
    </StyledProgress>
  );
};

Progress.propTypes = {
  height: number,
  timeEstimate: number,
  totalTimeSpent: number,
  timeSpendPercentage: number,
};

Progress.defaultProps = {
  timeSpendPercentage: 0,
  totalTimeSpent: 0,
  timeEstimate: 0,
  height: 10,
};
