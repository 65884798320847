import React, { useEffect } from 'react';
import { node, string, object, oneOfType, func } from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import R from 'ramda';

import { appRoutes } from './route-configs';
import { DEFAULT_PAGE_TITLE, ROUTE_NAMES } from './constants';
import { saveOriginUrl } from '../redux/actions/auth';

const mapStateToProps = ({ projects, estimations, auth, router }) => ({
  currentProjectName: projects.currentItem.name,
  currentEstimationName: estimations.currentItem.name,
  originUrl: auth.originUrl,
  location: router.location,
});

const mapDispatchToProps = {
  saveUrl: saveOriginUrl,
};

const RoutePage = ({
  layout: Layout,
  component: Component,
  title,
  currentProjectName,
  currentEstimationName,
  originUrl,
  saveUrl,
  ...props
}) => {
  if (originUrl === null) {
    const givenUrl = props.location.pathname + props.location.search;
    saveUrl(givenUrl);
  }

  useEffect(() => {
    const mockPart =
      document.title !== DEFAULT_PAGE_TITLE ? document.title : '';

    const firstTitlePart = R.cond([
      [
        R.equals(ROUTE_NAMES.PROJECT_DETAILS),
        () => currentProjectName || mockPart,
      ],
      [
        R.equals(ROUTE_NAMES.ESTIMATION),
        () => currentEstimationName || mockPart,
      ],
      [R.equals(ROUTE_NAMES.ESTIMATION_PREVIEW), () => currentEstimationName],
      [R.T, () => title],
    ])(title);

    const pageTitle = firstTitlePart
      ? `${firstTitlePart} | ${DEFAULT_PAGE_TITLE}`
      : DEFAULT_PAGE_TITLE;

    document.title = pageTitle;
  }, [title, currentProjectName, currentEstimationName]);

  return (
    <Layout>
      <Component {...props} />
    </Layout>
  );
};

const ConnectedRoutePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoutePage);

RoutePage.defaultProps = {
  title: '',
  currentProjectName: '',
  currentEstimationName: '',
};

RoutePage.propTypes = {
  title: string,
  currentProjectName: string,
  currentEstimationName: string,
  layout: node.isRequired,
  component: node.isRequired,
  originUrl: oneOfType([string, null]).isRequired,
  location: object.isRequired,
  saveUrl: func.isRequired,
};

export const Routes = () => (
  <Switch>
    {appRoutes.map(([routeName, Layout, Component, title]) => (
      <Route
        key={routeName}
        path={routeName}
        exact
        render={props => (
          <ConnectedRoutePage
            title={title}
            layout={Layout}
            component={Component}
            {...props}
          />
        )}
      />
    ))}
  </Switch>
);
