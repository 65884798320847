import * as R from 'ramda';

import { ROUTES } from './constants';

/**
 * projectRoute :: ID -> String
 */
export const projectRoute = projectId => `${ROUTES.PROJECTS}/${projectId}`;

/**
 * projectPreviewRoute :: String -> URL
 */
export const projectPreviewRoute = (previewToken, costToken) => {
  // shortId package generates preview token with length between 7 and 14 chars
  // if string has greater length - we should use the old preview route
  const newUrl = `${window.location.origin}${ROUTES.ESTIMATION_PREVIEW.replace(
    ':previewToken',
    previewToken
  )}`;
  // const oldUrl = `${window.location.origin}${ROUTES.PROJECT_PREVIEW_OLD}?previewToken=${previewToken}`;

  // if (previewToken && previewToken.length > 14) {
  //   return R.isEmpty(costToken) ? oldUrl : `${oldUrl}&costToken=${costToken}`;
  // }

  return R.equals(costToken, false)
    ? newUrl
    : `${newUrl}?costToken=${costToken}`;
};

/**
 * doesAnyRouteMatchCurrent :: [String, ReactComponent] -> Object -> Boolean
 */
export const doesAnyRouteMatchCurrent = routes =>
  R.converge(R.contains, [R.prop('route'), () => R.map(R.head, routes)]);

/**
 * routeNameMatchCurrent :: String -> Object -> Boolean
 */
export const routeNameMatchCurrent = R.propEq('pathname');
