import { authRequest } from './utils';

/**
 * updateProfile :: Object -> Promise Error _
 */
export const updateProfile = object =>
  authRequest({
    method: 'PATCH',
    url: '/auth/me/edit',
    data: object,
  }).then(({ data }) => data);
