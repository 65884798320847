import { mergeLeft } from 'ramda';
import { handleActions } from 'redux-actions';

import {
  fetchTeamRequest,
  fetchTeamSuccess,
  fetchTeamFailure,
} from '../../actions/autopilot';

const defaultState = {
  items: [],
  isFetching: true,
};

const fetchTeamSuccessReducer = (state, { payload }) => ({
  ...state,
  isFetching: false,
  items: payload,
});

export const autopilotTeamReducer = handleActions(
  {
    [fetchTeamSuccess]: fetchTeamSuccessReducer,
    [fetchTeamRequest]: mergeLeft({ isFetching: true }),
    [fetchTeamFailure]: mergeLeft({ isFetching: false }),
  },
  defaultState
);
