import styled from 'styled-components';
import { prop } from 'ramda';

export const Progress = styled.div`
  display: flex;
  height: ${prop('height')}px;
  border-radius: 30px;
  position: relative;
  background-color: ${props => props.theme.colors.borderLightSilver};
`;

Progress.Bar = styled.div`
  border-radius: 30px;
  width: ${prop('percent')}%;
  background-color: ${({ theme, status }) => theme.colors.progressbar[status]};
`;

Progress.Estimate = styled.div`
  position: absolute;
  left: 0;
  bottom: -1px; // border adds 2px to height
  height: 100%;
  box-sizing: content-box;
  border-radius: 30px;
  width: ${prop('percent')}%;
  border: 1px solid black;
`;

export const Compare = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  margin-bottom: 4px;
  padding: 0 4px;
`;

Compare.Item = styled.div``;

Compare.Label = styled.span`
  margin-right: 4px;
  color: ${props => props.theme.colors.fontGray};
`;

Compare.Value = styled.span`
  color: ${props => props.theme.colors.table.title};
`;

export const TimeTrackingWrapper = styled.div`
  // TODO: set min-width by props
  min-width: 250px;

  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`};
`;
