import { lazy } from 'react';
import { ROUTES, ROUTE_NAMES } from './constants';

const InviteUser = lazy(() =>
  import(/* webpackPrefetch: true */ '../containers/invite-user')
);
const SignInForm = lazy(() =>
  import(/* webpackPrefetch: true */ '../containers/sign-in-form')
);
const ProfileSettings = lazy(() =>
  import(/* webpackPrefetch: true */ '../containers/profile-settings')
);
const OrganizationSettings = lazy(() =>
  import(/* webpackPrefetch: true */ '../containers/organization-settings')
);
const NotFound = lazy(() =>
  import(/* webpackPrefetch: true */ '../components/ui-helpers/not-found').then(
    module => ({
      default: module.NotFound,
    })
  )
);
const AuthLayout = lazy(() =>
  import(/* webpackPrefetch: true */ '../layouts').then(module => ({
    default: module.AuthLayout,
  }))
);
const MainLayout = lazy(() =>
  import(/* webpackPrefetch: true */ '../layouts').then(module => ({
    default: module.MainLayout,
  }))
);
const PreviewLayout = lazy(() =>
  import(/* webpackPrefetch: true */ '../layouts').then(module => ({
    default: module.PreviewLayout,
  }))
);
const NoAuthLayout = lazy(() =>
  import(/* webpackPrefetch: true */ '../layouts').then(module => ({
    default: module.NoAuthLayout,
  }))
);

// ======================= NEW =======================
const ProjectsListPage = lazy(() =>
  import(/* webpackPrefetch: true */ '../containers/projects-list').then(
    module => ({
      default: module.ProjectsListContainer,
    })
  )
);

const ProjectPage = lazy(() =>
  import(/* webpackPrefetch: true */ '../containers/project').then(module => ({
    default: module.ProjectContainer,
  }))
);

const EstimationPage = lazy(() =>
  import(/* webpackPrefetch: true */ '../containers/estimation').then(
    module => ({
      default: module.EstimationContainer,
    })
  )
);
const EstimationPreviewPage = lazy(() =>
  import(/* webpackPrefetch: true */ '../containers/estimation').then(
    module => ({
      default: module.EstimationPreviewContainer,
    })
  )
);
const EstimationExportPage = lazy(() =>
  import(/* webpackPrefetch: true */ '../containers/estimation').then(
    module => ({
      default: module.EstimationExportContainer,
    })
  )
);

const Library = lazy(() =>
  import(/* webpackPrefetch: true */ '../containers/library').then(module => ({
    default: module.LibraryContainer,
  }))
);

const SearchPage = lazy(() =>
  import(/* webpackPrefetch: true */ '../containers/search').then(module => ({
    default: module.SearchPageContainer,
  }))
);

const FeedbackPage = lazy(() =>
  import(/* webpackPrefetch: true */ '../containers/feedback').then(module => ({
    default: module.FeedbackContainer,
  }))
);

export const appRoutes = [
  // [path, layout, component, page title]
  [ROUTES.SIGN_IN, NoAuthLayout, SignInForm, ROUTE_NAMES.SIGN_IN],
  [ROUTES.INVITE_USER, NoAuthLayout, InviteUser, ROUTE_NAMES.INVITE_USER],
  [
    ROUTES.EDIT_USER_DATA,
    AuthLayout,
    ProfileSettings,
    ROUTE_NAMES.EDIT_USER_DATA,
  ],
  [
    ROUTES.ORGANIZATION_SETTINGS,
    MainLayout,
    OrganizationSettings,
    ROUTE_NAMES.ORGANIZATION_SETTINGS,
  ],
  [ROUTES.NOT_FOUND, MainLayout, NotFound, ROUTE_NAMES.NOT_FOUND],
  [
    ROUTES.PROJECT_DETAILS,
    MainLayout,
    ProjectPage,
    ROUTE_NAMES.PROJECT_DETAILS,
  ],
  [
    ROUTES.ESTIMATION_PREVIEW,
    PreviewLayout,
    EstimationPreviewPage,
    ROUTE_NAMES.ESTIMATION_PREVIEW,
  ],
  [ROUTES.ESTIMATION, MainLayout, EstimationPage, ROUTE_NAMES.ESTIMATION],
  [ROUTES.LIBRARY, MainLayout, Library, ROUTE_NAMES.LIBRARY],
  [ROUTES.ESTIMATION_EXPORT, PreviewLayout, EstimationExportPage],
  [ROUTES.PROJECTS, MainLayout, ProjectsListPage, ROUTE_NAMES.PROJECTS],
  [ROUTES.HOME, MainLayout, ProjectsListPage, ROUTE_NAMES.PROJECTS],
  [ROUTES.SEARCH, MainLayout, SearchPage, ROUTE_NAMES.SEARCH],
  [ROUTES.FEEDBACK, PreviewLayout, FeedbackPage, ROUTE_NAMES.FEEDBACK],
];
