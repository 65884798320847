import * as R from 'ramda';
import { combineActions, handleActions } from 'redux-actions';

import {
  fetchUsersFailure,
  fetchUsersRequest,
  fetchUsersSuccess,
  archiveUser,
  deactivateUser,
  changeUserRole,
  updateUser,
  clearUsers,
  restoreUser,
  fetchUserByIdRequest,
  changeAllowedProjectsRequest,
  changeAllowedProjectsSuccess,
  changeAllowedProjectsFailure,
} from '../actions/users';

const defaultState = {
  users: {},
  user: {},
  isFetching: false,
};

// usersToItems :: [Contact] -> Object
const usersToItems = R.reduce((acc, c) => R.assoc(c._id, c, acc), {});

const fetchUsersSuccessReducer = (state, { payload }) => ({
  ...state,
  isFetching: false,
  isInviteUser: false,
  users: { ...state.users, ...usersToItems(payload.users) },
});
const fetchUserByIdReducer = (state, { payload }) => ({
  ...state,
  user: { ...state.user, ...payload.user },
});
const changeUserRoleReducer = (state, { payload }) => ({
  ...state,
  users: { ...state.users, [payload._id]: { ...payload } },
});

const updateUserReduser = (state, { payload: { userId, modifier } }) => {
  const updatedUser = {
    ...state.users[userId],
    ...modifier,
  };

  return {
    ...state,
    user: updatedUser,
    users: {
      ...state.users,
      [userId]: updatedUser,
    },
  };
};

const archiveOrRestoreUser = (isArchive = false) => (state, { payload }) => {
  return {
    ...state,
    users: {
      ...state.users,
      [payload]: {
        ...state.users[payload],
        archived: isArchive,
      },
    },
  };
};

const changeAllowedProjectsReducer = (state, { payload }) => ({
  ...state,
  isFetching: false,
  users: { ...state.users, [payload._id]: payload },
});

export const usersReducers = handleActions(
  {
    [combineActions(
      fetchUsersRequest,
      changeAllowedProjectsRequest
    )]: R.mergeDeepLeft({ isFetching: true }),
    [combineActions(
      fetchUsersFailure,
      changeAllowedProjectsFailure
    )]: R.mergeDeepLeft({ isFetching: false }),
    [fetchUsersSuccess]: fetchUsersSuccessReducer,
    [deactivateUser]: (state, { payload }) =>
      R.dissocPath(['users', payload], state),
    [archiveUser]: archiveOrRestoreUser(true),
    [restoreUser]: archiveOrRestoreUser(false),
    [changeUserRole]: changeUserRoleReducer,
    [updateUser]: updateUserReduser,
    [clearUsers]: state => ({ ...state, ...defaultState }),
    [fetchUserByIdRequest]: fetchUserByIdReducer,
    [changeAllowedProjectsSuccess]: changeAllowedProjectsReducer,
  },
  defaultState
);
